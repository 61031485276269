import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';
import * as types from '../../../actions/types';
import {store} from './../../../store';

const AccPagination = ({acc}) => {

    const setIndex = (e) => {
        const key = parseInt(e.currentTarget.dataset.key)-1;
        store.dispatch({
            type: types.SELECT_ACCOUNT,
            payload: {id:acc.id, number:key}
        })
    }

    if (!acc.accsToAdd.length || !acc.selected) return null;

    const pages = [];
    const num = acc.accsToAdd.length;
    if (num < 7)
        for (let index = 1; index <= num; index++) {
            pages.push(<li onClick={setIndex} data-key={index} key={index}><span className={classNames({ 'pagination-link': true, 'is-current': acc.accsToAdd[index-1].current })} title={'Go to account #' + index}>{index}</span></li >)
        }
    else {
        
        let currentIndex =  acc.accsToAdd.findIndex(accToAdd => accToAdd.current) + 1;
        let middle = Math.ceil(num / 2);
        if (currentIndex !== 1 && currentIndex !== num) {
            middle = currentIndex;
        }

        if (middle > 2 || currentIndex === 1) {
            pages.push(<li onClick={setIndex} data-key={1} key={1}><span className={classNames({ 'pagination-link': true, 'is-current': currentIndex === 1 })} title={'Go to account #1'}>1</span></li >);
            if (middle - 2 > 1 || currentIndex === 1) { pages.push(<li className="ellipsis-container" key={-1}><span className="pagination-ellipsis">&hellip;</span></li>); }
        }

        for (let index = middle - 1; index <= middle + 1; index++) {
            pages.push(<li onClick={setIndex} data-key={index} key={index}><span className={classNames({ 'pagination-link': true, 'is-current': currentIndex === index })} title={'Go to account #' + index}>{index}</span></li >)
        }

        if (middle + 1 < num || currentIndex === num) {
            if (middle + 2 < num) { pages.push(<li className="ellipsis-container" key={-2}><span className="pagination-ellipsis">&hellip;</span></li>); }
            pages.push(<li onClick={setIndex} data-key={num} key={num}><span className={classNames({ 'pagination-link': true, 'is-current': currentIndex === num })} title={'Go to account #' + num}>{num}</span></li >)
        }

    }

    return (
        <div className="counter">
            <nav className="pagination is-rounded is-centered" role="navigation" aria-label="pagination">
                <ul className="pagination-list">
                    {pages}
                </ul>
            </nav>
        </div>
    )
}

AccPagination.propTypes = {
    acc: PropTypes.object.isRequired
}

export default AccPagination

