import React, { useState, Fragment } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

const Tab = (props) => {
    return (
        <li className={props.tabIndex === props.activeTabIndex ? "is-active" : ""} onClick={() => { props.changeActiveTab(props.tabIndex); }}>
            <a href={"#" + props.tab.anchor}>
                {<span className="icon"><i className={props.tab.icon}></i></span>}
                <span>{props.tab.name}</span>
            </a>
        </li>
    );
};

const ActiveTabContent = (props) => <div className="is-fullwidth">{props.content}</div>;

const TabsComponent = ({ tabs, title, history }) => {

    const hashIndex = tabs.findIndex(tab => ('#' + tab.anchor === history.location.hash));

    const [activeTabIndex, changeActiveTab] = useState(
        hashIndex > 0 ? hashIndex : 0
    );


    return <Fragment>
        {title && <h3 className="has-text-centered" style={{ marginBottom: '15px' }}>{title}</h3>}
        <div className="tabsWrapper">
            {tabs.length > 1 &&
                <div className="tabs is-centered is-medium is-fullwidth">
                    <ul>
                        {tabs.map((tab, index) => <Tab tab={tab} tabIndex={index} key={index} activeTabIndex={activeTabIndex} changeActiveTab={changeActiveTab} />)}
                    </ul>
                </div>}

            <ReactCSSTransitionGroup
                className="tabs-content is-fullwidth"
                component="div"
                transitionName="fade"
                transitionEnterTimeout={0}
                transitionLeaveTimeout={150}>
                <ActiveTabContent key={activeTabIndex} content={tabs[activeTabIndex].content} />
            </ReactCSSTransitionGroup>
        </div>
    </Fragment >
}


export default withRouter(connect()(TabsComponent));