import React, { useState, useRef } from 'react'
import { store } from './../../../store'
import uuid from 'uuid';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import classNames from 'classnames'
import BeneficiariesSlider from './BeneficiariesSlider'
import BeneficiaryForm from './BeneficiaryForm'
import * as types from './../../../actions/types'

const StepBeneficiaries = ({ accData, history, setAlert, beneficiaries, percentages, about, updateBen }) => {

    const [activeAccount, setActiveAccount] = useState(accData.accountTypes.filter(x => x.id !== 6).find(x => x.selected).id + "_0");
    const [dropdownActive, setdropdownActive] = useState(false);

    const benRef = useRef({});

    const addBen = () => {
        showBen(null);
    }

    const showBen = (beneficiary) => store.dispatch({ type: types.SET_MODAL, payload: { title: 'Beneficiary', saveButton: saveBen, content: <BeneficiaryForm stateRef={benRef} parentAccount={activeAccount} parentBeneficiary={beneficiary} /> } })

    const saveBen = () => { return benRef.current.validate() ? updateBen(benRef, activeAccount) : false; }

    const updateBens = (e) => {
        setActiveAccount(e.target.value);
    }

    const needSpouseMenu = (['Married', 'Common-law'].includes(about.maritalStatus) && (!Array.isArray(percentages[activeAccount]) || !Array.isArray(beneficiaries) || (!percentages[activeAccount].find(x => x.guid === (beneficiaries.find(y => y.type === 'spouse') ? beneficiaries.find(y => y.type === 'spouse').guid : '')))));
    let hasDropdownBeneficiaries = (needSpouseMenu || beneficiaries.filter(x => (x.firstName !== '' && x.type !== 'spouse' && (!Array.isArray(percentages[activeAccount]) || !percentages[activeAccount].find(y => y.guid === x.guid)))).length > 0);

    return <div id="stepContainer" className="stepContainer has-text-centered">
        <div className="bHeader">
            <div className={classNames({ "dropdown": true, "is-active": dropdownActive })}>
                <div className="dropdown-trigger">
                    <button onBlur={(e) => { if (!(e.relatedTarget && e.relatedTarget.className === 'dropdown-item')) setdropdownActive(false); }} onClick={() => {
                        if (Array.isArray(percentages[activeAccount]) && percentages[activeAccount].length >= 5) {
                            setAlert('Beneficiaries limit is reached for this account', 'is-danger');
                            return;
                        }
                        if (!hasDropdownBeneficiaries) addBen(); else setdropdownActive(!dropdownActive);
                    }
                    } className="button" aria-haspopup="true" aria-controls="dropdown-menu">
                        <span>Add beneficiary</span>
                    </button>
                </div>

                {hasDropdownBeneficiaries &&
                    <div className="dropdown-menu has-text-left" id="dropdown-menu" role="menu">
                        <div className="dropdown-content">
                            <a href="#!" onClick={() => { addBen(); setdropdownActive(false); }} className="dropdown-item">
                                <i className="fas fa-user-plus"></i> New beneficiary
                        </a>

                            <hr className="dropdown-divider" />
                            {needSpouseMenu &&
                                <a href="#!" onClick={() => {
                                    let ben = beneficiaries.find(x => x.type === 'spouse');
                                    if (!ben) ben = { type: 'spouse', firstName: about.spouse.firstName ? about.spouse.firstName : 'Spouse', lastName: about.spouse.lastName, selectedAccounts: [] }
                                    updateBen({ ...ben, selectedAccounts: [...ben.selectedAccounts, activeAccount] }, activeAccount); setdropdownActive(false);
                                }} className="dropdown-item">
                                    <i className="fas fa-ring" style={{ marginRight: '5px' }}></i>{about.spouse.firstName ? about.spouse.firstName + ' ' + about.spouse.lastName : 'Spouse'}
                                </a>
                            }
                            {
                                beneficiaries.filter(x => (x.firstName !== '' && x.type !== 'spouse' && (!Array.isArray(percentages[activeAccount]) || !percentages[activeAccount].find(y => y.guid === x.guid)))).map((ben, index) => {
                                    return <a key={index} href="#!" onClick={() => { updateBen({ ...ben, selectedAccounts: [...ben.selectedAccounts, activeAccount] }, activeAccount); setdropdownActive(false); }} className="dropdown-item">
                                        {ben.firstName + ' ' + ben.lastName}
                                    </a>
                                })
                            }
                        </div>
                    </div>
                }
            </div>

            <span style={{ marginRight: '10px', marginLeft: '10px' }}>to your</span>
            <div className={classNames({ "select": true })} >
                <select onChange={updateBens} value={activeAccount} required name="selectBen" id="selectBen">
                    {accData.accountTypes.filter(x => x.id !== 6).map(val => {
                        if (val.selected) {
                            return val.accsToAdd.map((acc, index) => {
                                return <option key={val.id + '_' + index} value={val.id + '_' + index}>{val.name + (val.accsToAdd.length > 1 ? " #" + (index + 1) : '')}</option>
                            })
                        } else return null;
                    })}
                </select>
            </div>
        </div>

        <BeneficiariesSlider account={activeAccount} spouse={about.spouse} updateBen={showBen} beneficiaries={beneficiaries.filter(b => Array.isArray(b.selectedAccounts) && b.selectedAccounts.includes(activeAccount)).map(x => ({ ...x, percentage: percentages[activeAccount].find(y => y.guid === x.guid).percentage }))} />
    </div>
}

const mapStateToProps = state => ({
    accData: state.accData,
    about: state.about,
    beneficiaries: state.beneficiaries.beneficiariesList,
    percentages: state.beneficiaries.percentages
})

const mapDispatchToProps = dispatch => ({
    updateBen: (benRef, activeAccount) => dispatch({
        type: types.UPDATE_BENEFICIARY,
        payload: typeof (benRef.current) === 'object' ? benRef.current.beneficiary : benRef
    }),
    setAlert: (msg, alertType, timeout = 3000) => dispatch({
        type: types.SET_ALERT,
        payload: { msg, alertType, timeout, uuid: uuid.v4() }
    })

});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepBeneficiaries))