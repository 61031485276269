import * as types from '../actions/types';
import uuidv4 from 'uuid/v4';

const initialState =
{
    institution: '1',
    transitNumber: '',
    accountNumber: '',
    wantContribute: '',
    wantTransfer: '',
    uploadedFile: '',
    contributions: [],
    transfers: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case types.UPDATE_FIELD_BANKING:
            return ({
                ...state,
                [action.target.name]: action.target.value
            });
        case types.UPDATE_CONTRIBUTION:
            let newContribution = action.payload;
            const guid = newContribution.guid ? newContribution.guid : uuidv4();
            if (!newContribution.guid) {
                //new contribution
                return {
                    ...state,
                    contributions: [...state.contributions, { ...newContribution, guid }]
                }
            } else {
                //existing contribution
                return {
                    ...state,
                    contributions: state.contributions.map(x => x.guid === guid ? newContribution : x)
                }
            }
        case types.UPDATE_TRANSFER:
            let newTransfer = action.payload;
            const guid_transfer = newTransfer.guid ? newTransfer.guid : uuidv4();
            if (!newTransfer.guid) {
                //new transfer
                return {
                    ...state,
                    transfers: [...state.transfers, { ...newTransfer, guid: guid_transfer }]
                }
            } else {
                //existing transfer
                return {
                    ...state,
                    transfers: state.transfers.map(x => x.guid === guid_transfer ? newTransfer : x)
                }
            }
        case types.DELETE_CONTRIBUTION:
            return {
                ...state,
                wantContribute: state.contributions.filter(x => x.guid !== action.payload.guid).length > 0 ? 'yes' : 'no',
                contributions: state.contributions.filter(x => x.guid !== action.payload.guid),
            }
        case types.DELETE_TRANSFER:
            return {
                ...state,
                wantTransfer: state.transfers.filter(x => x.guid !== action.payload.guid).length > 0 ? 'yes' : 'no',
                transfers: state.transfers.filter(x => x.guid !== action.payload.guid),
            }
        default:
            return state;
    }
}
