import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { setAlert } from '../../../actions/alert'
import { countryList, camelToSentence } from '../../../utils/misc';

const Confirmations = ({ parentConfirmations, stateRef, setAlert }) => {
    const [hasError, setHasError] = useState(false);

    const [confirmations, setConfirmations] = useState(parentConfirmations);

    const updateFields = e => {
        setConfirmations({
            ...confirmations,
            [e.target.name]: e.target.type==='checkbox'?e.target.checked : e.target.value
        });
        if (e.target.value.trim() && hasError === e.target.name) setHasError('');
    };

    const validate = () => {
        let fieldsToCheck = ['relationship','title','firstName', 'lastName', 'street', 'city', 'postalCode', 'country'];
        for (let i = 0, len = fieldsToCheck.length; i < len; i++) {
            if (!confirmations[fieldsToCheck[i]].trim()) {
                setAlert(camelToSentence(fieldsToCheck[i])+' is required', 'is-danger', 3000);
                setHasError(fieldsToCheck[i]);
                return false;
            }
        }
        return true;
    }

    stateRef.current = { confirmations, validate }

    useEffect(() => {
        stateRef.current = { ...stateRef.current, confirmations }

    }, [confirmations, stateRef])

    useEffect(() => {
        setConfirmations(parentConfirmations);
    }, [parentConfirmations])

    return (
        <Fragment>
            
            <div className="columns field" style={{marginTop:'0',marginBottom:'20px'}}>
                <div className="column">
                    <label className="checkbox"><input onChange={updateFields} name="duplicateConfirmations" checked={confirmations.duplicateConfirmations} type="checkbox" />Duplicate Confirmations</label>
                </div>
                <div className="column">
                    <label className="checkbox"><input onChange={updateFields} name="duplicateStatements" checked={confirmations.duplicateStatements} type="checkbox" />Duplicate Statements</label>
                </div>
            </div>
            <div className="columns field">
                <div className="column">
                    <label htmlFor="unit" className="label">Relationship to applicant</label>
                    <p className="control">
                        <input onChange={updateFields} value={confirmations.relationship} className={classNames({ "input": true, "is-danger":hasError==='relationship'})} name="relationship" id="relationship" type="text" placeholder="" />
                    </p>
                </div>
                <div className="column">
                    <label htmlFor="title" className="label">Title</label>
                    <div className={classNames({ "select is-fullwidth": true, "is-danger":hasError==='title'})}><select onChange={updateFields} value={confirmations.title} required name="title"><option value="">Title</option><option value="Mr.">Mr.</option> <option value="Mrs.">Mrs.</option> <option value="Ms.">Ms.</option> <option value="Dr.">Dr.</option></select></div>
                </div>
            </div>

            <div className="columns field">
                <div className="column is-4">
                    <label htmlFor="firstName" className="label">First name</label>
                    <p className="control">
                        <input onChange={updateFields} value={confirmations.firstName} className={classNames({ "input": true, "is-danger":hasError==='firstName'})} name="firstName" type="text" required placeholder="First Name" />
                    </p>
                </div>
                <div className="column is-4">
                    <label htmlFor="middleName" className="label">Middle name</label>
                    <p className="control">
                        <input onChange={updateFields} value={confirmations.middleName} className="input" name="middleName" type="text" placeholder="Middle Name" />
                    </p>
                </div>
                <div className="column is-4">
                    <label htmlFor="lastName" className="label">Last name</label>
                    <p className="control">
                        <input onChange={updateFields} value={confirmations.lastName} className={classNames({ "input": true, "is-danger":hasError==='lastName'})} name="lastName" type="text" required placeholder="Last Name" />
                    </p>
                </div>
            </div>

            <div className="columns field">
                <div className="column is-narrow">
                    <label htmlFor="unit" className="label">Unit</label>
                    <p className="control">
                        <input onChange={updateFields} value={confirmations.unit} className="input" name="unit" id="unit" type="text" placeholder="" />
                    </p>
                </div>
                <div className="column">
                    <label htmlFor="street" className="label">Street</label>
                    <p className="control">
                        <input onChange={updateFields} value={confirmations.street} className={classNames({ "input": true, "is-danger":hasError==='street'})} name="street" id="street" type="text" placeholder="" />
                    </p>
                </div>
            </div>


            <div className="columns field">
                <div className="column is-4">
                    <label htmlFor="city" className="label">City</label>
                    <p className="control">
                        <input onChange={updateFields} value={confirmations.city} className={classNames({ "input": true, "is-danger":hasError==='city'})} name="city" id="city" type="text" placeholder="" />
                    </p>
                </div>

                <div className="column is-4">
                    <label htmlFor="province" className="label">Province/State</label>
                    <p className="control">
                        <input onChange={updateFields} value={confirmations.province} className="input" name="province" id="province" type="text" placeholder="" />
                    </p>
                </div>

                <div className="column is-4">
                    <label htmlFor="postalCode" className="label">Postal Code</label>
                    <p className="control">
                        <input onChange={updateFields} value={confirmations.postalCode} className={classNames({ "input": true, "is-danger":hasError==='postalCode'})} name="postalCode" id="postalCode" type="text" placeholder="" />
                    </p>
                </div>
            </div>


            <div className="columns field">
                <div className="column is-full">
                    <label htmlFor="country" className="label">Country</label>
                    <div className="control has-icons-left">
                        <div className={classNames({ "select has-icons-left is-fullwidth": true, "is-danger":hasError==='country'})} >
                            <select onChange={updateFields} value={confirmations.country} required name="country" id="country">
                                <option value="">Country</option>
                                {countryList.map((val, index) => {
                                    return <option key={index} value={val}>{val}</option>
                                })}
                            </select>
                            <span className="icon is-left"><i className="fas fa-globe"></i></span>
                        </div>
                    </div>
                </div>                
            </div>
        </Fragment>
    )
}

Confirmations.propTypes = {
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, { setAlert })(Confirmations)