import React, { Fragment, useRef } from "react";
import { store } from "./../../../store";
import InputMask from "react-input-mask";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as types from "./../../../actions/types";
import classNames from "classnames";
import ContributionForm from "./ContributionForm";
import TransferForm from "./TransferForm";

const StepBanking = ({ updateFields, updateContribution, updateTransfer, deleteTransfer, deleteContribution, banking, accData }) => {
  const { institution, uploadedFile, transitNumber, accountNumber, wantContribute, wantTransfer } = banking;

  const contributionRef = useRef({});
  const addContribution = () => {
    showContribution(null);
  };
  const showContribution = (contribution) => store.dispatch({ type: types.SET_MODAL, payload: { title: "Contribution", saveButton: saveContribution, content: <ContributionForm stateRef={contributionRef} parentContribution={contribution} /> } });
  const saveContribution = () => {
    return contributionRef.current.validate() ? updateContribution(contributionRef) : false;
  };

  const transferRef = useRef({});
  const addTransfer = () => {
    showTransfer(null);
  };
  const showTransfer = (transfer) => store.dispatch({ type: types.SET_MODAL, payload: { title: "Transfer", saveButton: saveTransfer, content: <TransferForm stateRef={transferRef} parentTransfer={transfer} /> } });
  const saveTransfer = () => {
    return transferRef.current.validate() ? updateTransfer(transferRef) : false;
  };

  let upload;

  const getFile = (e) => {
    e.preventDefault();
    updateFields({ target: { name: "uploadedFile", value: e.target.files[0] ? e.target.files[0].name : "" } });
  };

  return (
    <div id="stepContainer" className="stepContainer stepBank">
      <h3 className="has-text-centered">Confirm your bank account information</h3>
      <div style={{ margin: "0 auto" }} className="columns is-centered is-multiline is-fullwidth bankingColumns">
        <div className="column is-full-mobile is-one-third-tablet is-one-third-desktop">
          <label htmlFor="institution" className="label">
            Institution
          </label>
          <div className="control">
            <div className="select is-fullwidth">
              <select onChange={updateFields} value={institution} required name="institution" id="institution">
                <option value="1">Airline Financial Credit Union Limited</option>
                <option value="2">Alberta Treasury Branches</option>
                <option value="3">All Trans Financial Services Credit Union Limited</option>
                <option value="4">Alliance des caisses populaires de l'Ontario Limite</option>
                <option value="5">Alterna Savings and Credit Union</option>
                <option value="6">Amex Bank of Canada</option>
                <option value="7">Arnstein Community Credit Union Limited</option>
                <option value="8">Atlantic Central</option>
                <option value="9">B2B Bank (formerly B2B Trust)</option>
                <option value="10">Bank of America National Association</option>
                <option value="11">Bank of Canada</option>
                <option value="12">Bank of China (Canada)</option>
                <option value="13">Bank of Montreal</option>
                <option value="14">Bank of Tokyo-Mitsubishi UFJ (Canada)</option>
                <option value="15">Bank West</option>
                <option value="16">Barclay's Bank PLC Canada Branch</option>
                <option value="17">BNP Paribas (Canada)</option>
                <option value="18">BofA Canada Bank</option>
                <option value="19">Bridgewater Bank</option>
                <option value="20">Canadian Imperial Bank of Commerce</option>
                <option value="21">Canadian Tire Bank</option>
                <option value="22">Canadian Western Bank</option>
                <option value="23">Capital One Bank (Canada Branch)</option>
                <option value="24">Central 1 Credit Union British Columbia</option>
                <option value="25">Central 1 Credit Union Ontario</option>
                <option value="26">CIBC Trust Corporation</option>
                <option value="27">Citibank Canada</option>
                <option value="28">Citibank N.A.</option>
                <option value="29">Citizens Bank of Canada</option>
                <option value="30">Coast Capital Savings Credit Union</option>
                <option value="31">Comerica Bank</option>
                <option value="32">Communication Technologies Credit Union Limited</option>
                <option value="33">Community Trust Company</option>
                <option value="34">Concentra Financial Services Association</option>
                <option value="35">Credit Union Central Alberta Limited</option>
                <option value="36">Credit Union Central of Manitoba Limited</option>
                <option value="37">Credit Union Central of Saskatchewan</option>
                <option value="38">CS Alterna Bank</option>
                <option value="39">CTC Bank of Canada</option>
                <option value="40">Desjardins Du Québec</option>
                <option value="41">Deutsche Bank AG</option>
                <option value="42">DirectCash Bank</option>
                <option value="43">Dundalk District Credit Union Limited</option>
                <option value="44">Dundee Bank of Canada</option>
                <option value="45">Effort Trust Company</option>
                <option value="46">Fifth Third Bank</option>
                <option value="47">First Commercial Bank</option>
                <option value="48">First Nations Bank of Canada</option>
                <option value="49">La Fédération des caisses populaires Acadiennes Limitée</option>
                <option value="50">La Fédération des caisses populaires de l'Ontario Inc.</option>
                <option value="51">La Fédération des caisses populaires du Manitoba Inc.</option>
                <option value="52">General Bank of Canada</option>
                <option value="53">Goderich Community Credit Union Limited</option>
                <option value="54">Golden Horseshoe Credit Union Limited</option>
                <option value="55">Habib Canadian Bank</option>
                <option value="56">HomEquity Bank</option>
                <option value="57">Household Trust Company</option>
                <option value="58">HSBC Bank Canada</option>
                <option value="59">HSBC Bank USA National Association</option>
                <option value="60">HSBC Mortgage Corporation (Canada)</option>
                <option value="61">ICICI Bank Canada</option>
                <option value="62">AO Trust Inc.</option>
                <option value="63">Industrial and Commercial Bank of China (Canada)</option>
                <option value="64">ING Bank of Canada</option>
                <option value="65">Investors Group Trust Co. Ltd.</option>
                <option value="66">J.P. Morgan Bank Canada</option>
                <option value="67">Jameson Bank</option>
                <option value="68">JPMorgan Chase Bank National Association</option>
                <option value="69">Korea Exchange Bank of Canada</option>
                <option value="70">Latvian Credit Union Limited</option>
                <option value="71">Laurentian Bank of Canada</option>
                <option value="72">Laurentian Trust of Canada Inc.</option>
                <option value="73">M&amp;T Bank</option>
                <option value="74">Manulife Bank of Canada</option>
                <option value="75">Manulife Trust Company</option>
                <option value="76">Maple Bank</option>
                <option value="77">Mega International Commercial Bank (Canada)</option>
                <option value="78">Meridian Credit Union</option>
                <option value="79">Mizuho Corporate Bank Ltd. Canada Branch</option>
                <option value="80">MonCana Bank of Canada</option>
                <option value="81">Montreal Trust Company of Canada</option>
                <option value="82">National Bank of Canada</option>
                <option value="83">National Trust Company</option>
                <option value="84">Ontario Civil Service Credit Union Limited</option>
                <option value="85">Pacific &amp; Western Bank of Canada</option>
                <option value="86">Peace Hills Trust Company</option>
                <option value="87">Peoples Trust Company</option>
                <option value="88">Rabobank Nederland</option>
                <option value="89">ResMor Trust Company</option>
                <option value="90">Royal Bank Mortgage Corporation</option>
                <option value="91">Royal Bank of Canada</option>
                <option value="92">Royal Bank of Scotland N.V. (Canada) Branch</option>
                <option value="93">Royal Trust Company</option>
                <option value="94">Royal Trust Corporation of Canada</option>
                <option value="95">Scotia Mortgage Corporation</option>
                <option value="96">Shinhan Bank Canada</option>
                <option value="97">Simplii Financial</option>
                <option value="98">Société Générale (Canada Branch)</option>
                <option value="99">Société Générale (Canada Branch) Ontario</option>
                <option value="100">State Bank of India (Canada) Alberta</option>
                <option value="101">State Street</option>
                <option value="102">Sumitomo Mitsui Banking Corporation of Canada</option>
                <option value="103">Sun Life Financial Trust Inc.</option>
                <option value="104">Tandia Credit Union</option>
                <option value="105">Tangerine Bank</option>
                <option value="106">TD Mortgage Corporation</option>
                <option value="107">TD Pacific Mortgage Corporation</option>
                <option value="108">The Bank of New York Mellon</option>
                <option value="109">The Bank of Nova Scotia</option>
                <option value="110">The Canada Trust Company</option>
                <option value="111">The Equitable Trust Company</option>
                <option value="112">The Northern Trust Company Canada Branch</option>
                <option value="113">The Toronto-Dominion Bank</option>
                <option value="114">U.S. Bank National Association</option>
                <option value="115">UBS AG Canada Branch</option>
                <option value="116">UBS Bank (Canada)</option>
                <option value="117">United Overseas Bank Limited</option>
                <option value="118">Vancouver City Savings Credit Union</option>
                <option value="119">Walmart Canada Bank</option>
                <option value="120">Flinks Capital</option>
              </select>
            </div>
          </div>
        </div>

        <div className="column is-full-mobile is-one-third-tablet is-one-third-desktop">
          <label htmlFor="institution" className="label">
            Transit number
          </label>
          <p className="control">
            <InputMask onChange={updateFields} value={transitNumber} className="input" name="transitNumber" id="transitNumber" type="text" required placeholder="12345" mask="99999" maskChar="" />
          </p>
        </div>

        <div className="column is-full-mobile is-one-third-tablet is-one-third-desktop">
          <label htmlFor="accountNumber" className="label">
            Account number
          </label>
          <p className="control">
            <InputMask onChange={updateFields} value={accountNumber} className="input" name="accountNumber" id="accountNumber" type="text" required placeholder="1234567" mask="999999999999" maskChar="" />
          </p>
        </div>
        <div className="column is-full">
          <h4
            style={{ display: "inline-block", cursor: "pointer" }}
            onClick={() => {
              upload.click();
            }}
          >
            <i style={{ color: "#2E63CC" }} className="fas fa-upload"></i>Bank confirmation
          </h4>
          {uploadedFile !== "" && <div>{uploadedFile}</div>}
          <div className="bankWrapper">
            <p style={{ lineHeight: "2.25em", verticalAlign: "middle" }}>
              <button
                onClick={() => {
                  upload.click();
                }}
                className="button"
              >
                Upload
              </button>{" "}
              an image or PDF file of your statement, which indicates the following information:
            </p>
            <input onChange={getFile} id="myInput" type="file" ref={(el) => (upload = el)} style={{ display: "none" }} />
            <ul className="bankUl">
              <li>Your name</li>
              <li>The name of the bank where the account is held</li>
              <li>Your account number</li>
              <li>The branch transit number &amp; institution number</li>
            </ul>
            <strong>An original PDF file of your banking statement is required. A scan or photo of your statement will not be accepted</strong>
          </div>

          {(wantContribute !== "yes" || banking.contributions.length === 0) && (
            <div style={{ marginBottom: "2rem" }} className="field">
              <div className="columns reversed">
                <div className="column is-narrow">
                  <div className={classNames({ "control has-actions": true })}>
                    <div className="select is-fullwidth">
                      <select
                        onChange={(e) => {
                          if (e.target.value === "yes") {
                            e.preventDefault();
                            addContribution();
                          }
                          updateFields(e);
                        }}
                        value={wantContribute}
                        name={`wantContribute`}
                        id={`wantContribute`}
                      >
                        <option value={"no"}>No</option>
                        <option value={"yes"}>Yes</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="column labelonly">
                  <label className="label" htmlFor={`wantContribute`}>
                    <span>Would you like to contribute to your new accounts directly from your bank account (i.e. chequings or saving account)?</span>
                  </label>
                </div>
              </div>
            </div>
          )}
          {wantContribute === "yes" && (
            <Fragment>
              {banking.contributions.length > 0 && <h3 style={{ marginBottom: "10px" }}>Contributions</h3>}
              <div className="cbankWrapper">
                {banking.contributions.map((contribution, index) => {
                  const acc = accData.accountTypes.find((x) => x.id === parseInt(contribution.account.split("_")[0]));
                  return (
                    <div key={index} className="cbank contribution">
                      <div className="columns is-fullwidth is-fullheight">
                        <div className="column is-5">
                          <div className="amountWrapper">
                            <div className="amount">
                              <div className="amountText">${contribution.amount}</div>
                            </div>
                            <i className="fas fa-arrow-right"></i>
                          </div>
                        </div>
                        <div className="column is-7">
                          <div className="title">
                            {acc.name} ({acc.code})
                          </div>
                          <div className="note">
                            {contribution.frequency} {contribution.frequency === "One-time" ? "on" : "from"} {contribution.effectiveDate}
                          </div>
                        </div>
                      </div>
                      <div className="deleter">
                        <span onClick={() => deleteContribution(contribution.guid)} className="is-link icon">
                          <i title="Delete contribution" className="fas fa-trash"></i>
                        </span>
                      </div>
                      <div className="edit">
                        <span onClick={() => showContribution(contribution)} title="Edit contribution" className="is-link">
                          Edit
                        </span>
                      </div>
                    </div>
                  );
                })}

                <div
                  onClick={() => {
                    addContribution();
                  }}
                  className="cbank empty is-link"
                >
                  <div className="title">
                    <span className="icon">
                      <i className="fas fa-plus-circle"></i>
                    </span>
                  </div>
                  <div className="note">Add {banking.contributions.length > 0 && "another "}contribution</div>
                </div>
              </div>
            </Fragment>
          )}

          <div style={{ clear: "both" }}></div>
          <br />

          {(wantTransfer !== "yes" || banking.transfers.length === 0) && (
            <div style={{ marginBottom: "2rem" }} className="field">
              <div className="columns reversed">
                <div className="column is-narrow">
                  <div className={classNames({ "control has-actions": true })}>
                    <div className="select is-fullwidth">
                      <select
                        onChange={(e) => {
                          if (e.target.value === "yes") {
                            e.preventDefault();
                            addTransfer();
                          }
                          updateFields(e);
                        }}
                        value={wantTransfer}
                        name={`wantTransfer`}
                        id={`wantTransfer`}
                      >
                        <option value={"no"}>No</option>
                        <option value={"yes"}>Yes</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="column labelonly">
                  <label className="label" htmlFor={`wantTransfer`}>
                    <span>Would you like to set up a transfer from your existing investment account?</span>
                  </label>
                </div>
              </div>
            </div>
          )}

          {wantTransfer === "yes" && (
            <Fragment>
              {banking.transfers.length > 0 && <h3 style={{ marginBottom: "10px" }}>Transfers</h3>}
              <div className="cbankWrapper">
                {banking.transfers.map((transfer, index) => {
                  const acc = accData.accountTypes.find((x) => x.id === parseInt(transfer.account.split("_")[0]));
                  return (
                    <div key={index} className="cbank transfer">
                      <div className="columns is-fullwidth is-fullheight">
                        <div className="column is-5">
                          <div className="amountWrapper">
                            <div className="amount">
                              <div className="amountText">{transfer.amountType === "full" ? "Full" : "$" + transfer.amount}</div>
                            </div>
                            <i className="fas fa-arrow-right"></i>
                          </div>
                        </div>
                        <div className="column is-7">
                          <div className="title">
                            {acc.name} ({acc.code})
                          </div>
                          <div className="note">{transfer.institution ? transfer.institution : transfer.accountNumber}</div>
                        </div>
                      </div>
                      <div className="deleter">
                        <span onClick={() => deleteTransfer(transfer.guid)} className="is-link icon">
                          <i title="Delete transfer" className="fas fa-trash"></i>
                        </span>
                      </div>
                      <div className="edit">
                        <span onClick={() => showTransfer(transfer)} title="Edit transfer" className="is-link">
                          Edit
                        </span>
                      </div>
                    </div>
                  );
                })}

                <div
                  onClick={() => {
                    addTransfer();
                  }}
                  className="cbank empty is-link"
                >
                  <div className="title">
                    <span className="icon">
                      <i className="fas fa-plus-circle"></i>
                    </span>
                  </div>
                  <div className="note">Add {banking.transfers.length > 0 && "another "}transfer</div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  accData: state.accData,
  banking: state.banking,
});

const mapDispatchToProps = (dispatch) => ({
  updateFields: (e) => {
    return dispatch({
      type: types.UPDATE_FIELD_BANKING,
      target: e.target,
    });
  },
  updateContribution: (contributionRef) =>
    dispatch({
      type: types.UPDATE_CONTRIBUTION,
      payload: typeof contributionRef.current === "object" ? contributionRef.current.contribution : contributionRef,
    }),
  updateTransfer: (transferRef) =>
    dispatch({
      type: types.UPDATE_TRANSFER,
      payload: typeof transferRef.current === "object" ? transferRef.current.transfer : transferRef,
    }),
  deleteContribution: (guid) =>
    dispatch({
      type: types.DELETE_CONTRIBUTION,
      payload: { guid },
    }),
  deleteTransfer: (guid) =>
    dispatch({
      type: types.DELETE_TRANSFER,
      payload: { guid },
    }),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepBanking));
