import React, { Fragment } from 'react'
import classNames from 'classnames';
import { setAlert } from '../../../actions/alert';
import { connect } from 'react-redux'
import * as types from './../../../actions/types'
import { store } from './../../../store'

const AffiliationsQuestion = ({ index, question, updateFields, updateAffiliationsCheckboxes, popupAction, setAlert }) => {
    return <Fragment>
        <div className="field">
            <div className="columns reversed">
                <div className="column is-narrow">
                    <div className={classNames({ "control has-actions": true, "has-icons-left": question.hasPopupOnYes && question.answer === 'yes' })}>
                        <div className="select is-fullwidth">
                            <select onChange={(e) => { if (question.hasPopupOnYes && e.target.value === 'yes') popupAction(); if (question.hasNote && e.target.value === 'yes') setAlert('Additional documents may be required.<br/>An associate will contact you.','is-warning', 5000); updateFields(e); }} value={question.answer} name={`affiliations.${index}`} id={`affiliations.${index}`}><option value={'no'}>No</option><option value={'yes'}>Yes</option></select>
                        </div>
                        {question.hasPopupOnYes && question.answer === 'yes' && <span onClick={() => popupAction()} className="icon is-left has-actions"><i style={{ color: '#3273dc' }} title="Edit" className="fas fa-edit"></i></span>}
                    </div>
                </div>
                <div className="column labelonly">
                    <label className="label" htmlFor={`affiliations.${index}`}>
                        <span dangerouslySetInnerHTML={{ __html: question.question }}></span>
                        {question.hasPopupOnYes && question.answer === 'yes' && <span onClick={() => popupAction()} className="is-link">Edit</span>}
                        {
                            question.info && <span onClick={() => store.dispatch({ type: types.SET_MODAL, payload: { title: 'Info', saveButton: false, content: question.info } })} className="icon is-right has-actions is-link"><i style={{ color: '#719CF0' }} className="fas fa-info-circle"></i></span>
                        }
                    </label>
                </div>
            </div>
        </div>
        {question.answer === 'yes' && typeof question.detailsCheckboxes !== 'undefined' &&
            <div className="field hasParent">
                {question.detailsCheckboxes.map((item, itemIndex) => {
                    return <div key={itemIndex}>
                        <label className="checkbox label"><input data-key={itemIndex} name={`affiliations.${index}.detailsCheckboxes.${itemIndex}`} onChange={
                            e=>{
                                if (index==='thirdParty' && e.target.checked) setAlert('Additional documents may be required.<br/>An associate will contact you.','is-warning', 5000);
                                updateAffiliationsCheckboxes(e);
                            }
                        } checked={item.answer} type="checkbox" />{item.label}</label>
                        {
                            item.label === 'Third Party' && item.answer && (
                                <label style={{marginLeft:'5px'}} className="label"><span onClick={() => popupAction()} className="is-link">Edit</span></label>
                            )
                        }
                    </div>
                })}


            </div>
        }
        {question.answer === 'yes' && typeof question.detailsText !== 'undefined' &&
            <div className="field hasParent">
                <label className="label" htmlFor={`affiliations.${index}.detailsText`}><span>{question.detailsText.label}</span></label>
                <input onChange={updateFields} value={question.detailsText.answer} className="input" name={`affiliations.${index}.detailsText`} type="text" required placeholder={question.detailsText.placeholder} />
            </div>
        }
    </Fragment>
}

export default connect(null, {setAlert})(AffiliationsQuestion)