import React, { Fragment, useState, useEffect } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { setAlert } from '../../../actions/alert'
import { camelToSentence, getAccList } from '../../../utils/misc';
import MultiSelect from '../../../utils/multiSelect'
import NumberFormat from 'react-number-format'

const Borrowed = ({ parentBorrowedList, stateRef, setAlert, accData }) => {
    const [hasError, setHasError] = useState(false);
    
    //const [borrowedList, setBorrowedList] = useState(parentBorrowedList.map(item=>({...item, account:item.account!==''?item.account : accData.accountTypes.find(x => x.selected)?accData.accountTypes.find(x => x.selected).id + "_0":"" }) ));
    const [borrowedList, setBorrowedList] = useState(parentBorrowedList);
 
    const validate = () => {
        let fieldsToCheck = ['amount', 'type', 'date', 'institution'];
        if (getAccList(accData.accountTypes).length>1) fieldsToCheck.push('selectedAccounts');
        for (let index in borrowedList) {
            for (let i = 0, len = fieldsToCheck.length; i < len; i++) {
                if ((fieldsToCheck[i]==='selectedAccounts' && !borrowedList[index][fieldsToCheck[i]].length) || !borrowedList[index][fieldsToCheck[i]]) {
                    setAlert(camelToSentence(fieldsToCheck[i])+' is required', 'is-danger', 3000);
                    setHasError(fieldsToCheck[i]+"_"+index);
                    return false;
                }
            }
        }
        return true;
    }

    stateRef.current = { borrowedList, validate }

    useEffect(() => {
        stateRef.current = { ...stateRef.current, borrowedList }
    }, [borrowedList, stateRef])

    const deleteItem = (index) => {
        setBorrowedList(
            borrowedList.filter((_, i) => i !== index)
        )
    }

    const addItem = () => {
        setBorrowedList(
            [...borrowedList, {amount:'',type:'Pre-existing',date:'',institution:'', selectedAccounts:[]}] //account:accData.accountTypes.find(x=>x.selected).id+"_0"
        )
    }

    const updateFields = e => {
        const key = parseInt(e.currentTarget.dataset.key);        
        setBorrowedList(
            borrowedList.map((entry, index) => index === key ? {...entry, [e.currentTarget.name.replace('borrowed','').split('_')[0].toLowerCase()]: e.currentTarget.value } : entry)
        );

        if (e.currentTarget.value && hasError === e.currentTarget.name.replace('borrowed','').toLowerCase()) setHasError('');
    };

    const updateAccountsList = (key, selectedAccounts) => {
        setBorrowedList(
            borrowedList.map((entry, index) => index === key ? {...entry, selectedAccounts: selectedAccounts } : entry )
        );
    }
    const accList = getAccList(accData.accountTypes);
    return (
        <Fragment>
            {borrowedList.map(
                (entry, index) => {
                    return <div className="oddBg" key={index}>
                       
                        <div className="columns field">
                            {accList.length>1 &&
                            <div className="column is-full-mobile is-three-fifths-tablet">
                                <div className="field">
                                    <label htmlFor={`borrowedAccount_${index}`} className="label">
                                        Which investment account(s) is this being used for?
                                        {index > 0 && <span title="Delete loan" onClick={() => { deleteItem(index) }}><span className="is-link icon is-left"><i className="fas fa-trash"></i></span></span>}
                                    </label>
                                    <div className={classNames({"control":true, 'dangerSelect':hasError===`selectedAccounts_${index}`})}>
                                        <div className={classNames({ "is-fullwidth": true, "is-danger": false })} >                                        
                                        <MultiSelect data-key={index} name={`borrowedAccount_${index}`} id={`borrowedAccount_${index}`} options={accList} disableSearch={true}
                                        selected={entry.selectedAccounts.filter(x=> accData.accountTypes.filter(acc => acc.selected && acc.id===parseInt(x.split('_')[0].toLowerCase()) ).length)} overrideStrings={{selectSomeItems: "Select account(s)", allItemsAreSelected: "All Accounts", selectAll: "Select All" }} onSelectedChanged={selectedAccounts =>updateAccountsList(index, selectedAccounts)  }/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="column is-full-mobile is-two-fifths-tablet">
                                <div className="field">
                                    <label htmlFor={`borrowedAmount_${index}`} className="label">
                                        How much is the loan for?
                                        {accList.length<=1 && index>0 && <span title="Delete loan" onClick={() => { deleteItem(index) }}><span className="is-link icon is-left"><i className="fas fa-trash"></i></span></span>}
                                    </label>
                                    <p className="control has-icons-left">
                                        <NumberFormat displayType={'input'} thousandSeparator={true} onChange={e=>updateFields({currentTarget: {name: `borrowedAmount_${index}`, dataset:{key:index}, value: e.target.value }})} value={entry.amount} data-key={index} className={classNames({ "input": true, "is-danger": hasError===`amount_${index}` })} name={`borrowedAmount_${index}`} id={`borrowedAmount_${index}`} required placeholder="" />
                                        <span className="icon is-left"><i className="fas fa-dollar-sign"></i></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="columns field">
                            <div className="column is-full-mobile is-three-fifths-tablet">
                                <div className="field">
                                    <label htmlFor={`borrowedType_${index}`} className="label">
                                        Is this a pre-existing or new loan?
                                    </label>
                                    <div className="control">
                                        <div className={classNames({ "select is-fullwidth": true, "is-danger": false })} >
                                            <select onChange={updateFields} data-key={index} value={entry.type} required name={`borrowedType_${index}`} id={`borrowedType_${index}`}>
                                                <option value="Pre-existing">Pre-existing</option>
                                                <option value="New">New</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="column is-full-mobile is-two-fifths-tablet">
                                <div className="field">
                                    <label htmlFor={`borrowedDate_${index}`} className="label">Date of the loan</label>
                                    <p className="control has-icons-left">
                                        <input onChange={updateFields} data-key={index} value={entry.date} className={classNames({ "input": true, "is-danger": hasError===`date_${index}` })} name={`borrowedDate_${index}`} id={`borrowedDate_${index}`} required type="date" placeholder="mm/dd/yyyy" />
                                        <span className="icon is-left"><i className="fas fa-calendar-alt"></i></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-full-mobile">
                                <div className="field">
                                    <label htmlFor={`borrowedInstitution_${index}`} className="label">Lending institution</label>
                                    <input onChange={updateFields} data-key={index} value={entry.institution} className={classNames({ "input": true, "is-danger": hasError===`institution_${index}` })} name={`borrowedInstitution_${index}`} id={`borrowedInstitution_${index}`} required type="text" placeholder="" />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            )}
            <button style={{marginLeft:'5px'}} onClick={addItem} className="button">Add loan</button>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    accData: state.accData
})

export default connect(mapStateToProps, { setAlert })(Borrowed)
  