import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { setAlert } from '../../../actions/alert'
import { camelToSentence } from '../../../utils/misc';
import NumberFormat from 'react-number-format'

const TransferForm = ({ parentTransfer, parentAccount, stateRef, accData, setAlert }) => {
    const [hasError, setHasError] = useState(false);
    const multiple_accounts = accData.accountTypes.filter(x => x.selected).length > 1;
    let upload;

    const [transfer, setTransfer] = useState(parentTransfer !== null ? parentTransfer : {
        account: parentAccount ? parentAccount : (multiple_accounts ? '' : accData.accountTypes.find(x => x.selected).id + "_0"),
        existing: '',
        institution: '',
        accountNumber: '',
        statement: '',
        amountType: 'full',
        uploadedFile: '',
        amount: ''
    });

    const updateFields = e => {
        setTransfer({
            ...transfer,
            [e.target.name]: e.target.value
        });
        if (e.target.value.trim() && hasError === e.target.name) setHasError('');
    };

    const validate = () => {
        const fieldsToCheck = ['accountNumber'];
        if (multiple_accounts) fieldsToCheck.push('account');
        if (transfer['existing'] !== 'yes') fieldsToCheck.push('institution');

        if (transfer['amountType'] === 'partial') fieldsToCheck.push('amount');

        for (let i = 0, len = fieldsToCheck.length; i < len; i++) {
            if (!transfer[fieldsToCheck[i]].trim() || (fieldsToCheck[i] === 'amount' && parseFloat(transfer[fieldsToCheck[i]]) === 0)) {
                setAlert(camelToSentence(fieldsToCheck[i]) + ' is required', 'is-danger', 3000);
                setHasError(fieldsToCheck[i]);
                return false;
            }
        }
        return true;
    }

    stateRef.current = { transfer, validate }

    return (
        <Fragment>
            {multiple_accounts &&
                <div className="columns field">
                    <div className="column is-12">
                        <label htmlFor={`account`} className="label">
                            Which account you wish to transfer your money to?
                    </label>
                        <div className="control">
                            <div className={classNames({ "select is-fullwidth": true, 'is-danger': hasError === `account` })} >
                                <select onChange={updateFields} value={transfer.account} required name="account" id="3">
                                    <option>Select account</option>
                                    {accData.accountTypes.map(val => {
                                        if (val.selected) {
                                            return val.accsToAdd.map((acc, index) => {
                                                return <option key={val.id + '_' + index} value={val.id + '_' + index}>{val.name + (val.accsToAdd.length > 1 ? " #" + (index + 1) : '')}</option>
                                            })
                                        } else return null;
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            }


            <div className="columns fiels reversed">
                <div className="column is-narrow">
                    <div className="control has-actions">
                        <div className="select is-fullwidth">
                            <select onChange={updateFields} value={transfer.existing} name="existing" id="existing"><option value={'no'}>No</option><option value={'yes'}>Yes</option></select>
                        </div>
                    </div>
                </div>
                <div className="column labelonly">
                    <label className="label" htmlFor="existing"><span>Would you like to transfer into you new *company_name* account from your existing *company_name* account?</span> </label>
                </div>
            </div>


            <div className="columns field">
                {transfer.existing !== 'yes' &&
                    <div className="column is-6">
                        <label htmlFor="institution" className="label">Name of reliquishing institution</label>
                        <p className="control">
                            <input type="text" onChange={updateFields} value={transfer.institution} className={classNames({ "input": true, "is-danger": hasError === `institution` })} name={`institution`} id={`institution`} required placeholder="" />
                        </p>
                    </div>}
                <div className={`column is-${transfer.existing === 'yes' ? '12' : '6'}`}>
                    <label htmlFor="accountNumber" className="label">Investor account number or member number</label>
                    <p className="control">
                        <input type="text" onChange={updateFields} value={transfer.accountNumber} className={classNames({ "input": true, "is-danger": hasError === `accountNumber` })} name={`accountNumber`} id={`accountNumber`} required placeholder="" />
                    </p>
                </div>
            </div>

            {transfer.existing !== 'yes' &&
                <div style={{lineHeight:'2.25em'}}>
                    <button onClick={() => { upload.click(); }} className="button has-icon"><i style={{ color: '#2E63CC',marginRight:'5px' }} className={classNames({"fas fa-upload":transfer.uploadedFile==='', "fas fa-check":transfer.uploadedFile!==''  })}></i>Upload</button> <label style={{ /*fointSize: '1.2em',*/ marginTop:'1.2em', cursor: 'pointer' }} htmlFor="uploadedFile" className="label">a statement for this institution (image/PDF file)</label>
                    <input ref={el => upload = el} type="file" onChange={(e) => { e.preventDefault(); updateFields({ target: { name: 'uploadedFile', value: e.target.files[0]?e.target.files[0].name:'' } }) }} name={`uploadedFile`} id={`uploadedFile`} style={{ display: 'none' }} />
                    {transfer.uploadedFile && <div>{transfer.uploadedFile}</div>}
                </div>
            }

            <div className="columns field">
                <div className={`column is-${transfer.amountType === 'partial' ? 6 : 12}`}>
                    <label htmlFor={`amountType`} className="label">
                        Amount
                    </label>
                    <div className={classNames({ "control": true, 'dangerSelect': hasError === `amountType` })}>
                        <div className="select is-fullwidth" >
                            <select onChange={updateFields} value={transfer.amountType} required name="amountType" id="amountType">
                                <option value="full">Full</option>
                                <option value="partial">Partial</option>
                            </select>
                        </div>
                    </div>
                </div>

                {transfer.amountType === 'partial' &&
                    <div className={`column is-6`}>
                        <p className="control has-icons-left">
                            <NumberFormat displayType={'input'} thousandSeparator={true} onChange={e => updateFields({ target: { name: `amount`, value: e.target.value } })} value={transfer.amount} className={classNames({ "input": true, "is-danger": hasError === `amount` })} name={`amount`} id={`amount`} required placeholder="" />
                            <span className="icon is-left"><i className="fas fa-dollar-sign"></i></span>
                        </p>
                    </div>
                }
            </div>

        </Fragment>
    )
}

TransferForm.propTypes = {
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    accData: state.accData
})

export default connect(mapStateToProps, { setAlert })(TransferForm)