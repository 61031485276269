import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setAlert } from '../../actions/alert';

const classNames = require('classnames');


const StepsMenu = (props) => {
    const menuitems = [['', 'Select accounts'], ['stepAbout', 'About you', 'personal']];
    if (props.accData.accountTypes.filter(account => { return account.selected && account.accsToAdd.filter(acctoAdd => { return acctoAdd.isJoint }).length }).length) menuitems.push(['stepJoint', 'Joint applicant' + (props.aboutJoint.length > 1 ? 's' : ''), 'personal.0']);
    if (props.accData.accountTypes.filter(account => account.id !== 6 && account.selected).length > 0) menuitems.push(['stepBeneficiaries', 'Beneficiaries']);
    menuitems.push(['stepBanking', 'Banking info']);
    if (props.accData.accountTypes.filter(account => account.id === 6 && account.selected).length > 0) menuitems.push(['stepVerification', 'ID verification']);
    let wasSelected = false;
    return <React.Fragment>
        <div className="has-text-centered" id="steps">
            {!props.accData.loading && props.history.location.pathname!=='/invisor' && menuitems.map((menuItem, index) => {
                const isSelected = props.location.pathname === '/' + menuItem[0];
                if (isSelected) wasSelected = true;
                return <Link onClick={(e)=>{
                    if (props.history.location.pathname==='/' && menuItem[0] && (!((typeof props.accData!=='undefined') && !props.accData.loading && props.accData.accountTypes.filter(acc => acc.selected).length)) ) {
                        e.preventDefault();
                        props.setAlert('Please select an account to open first', 'is-danger', 3000);
                    }
                }} key={index} to={`${process.env.PUBLIC_URL}/` + (menuItem[0] + (menuItem[2] ? "#" + menuItem[2] : ""))} className={classNames({ "button iaButton trButton bg-gray": true, "disabled": false && wasSelected && !isSelected, "is-current": isSelected, "is-selected": isSelected || !wasSelected })}><span>{index + 1}</span>
                    <div className='triangle'></div>{menuItem[1]}
                </Link>
            })}
        </div>
    </React.Fragment >
}

StepsMenu.propTypes = {
    accData: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    accData: state.accData,
    aboutJoint: state.aboutJoint.jointsArray
})

export default withRouter(connect(mapStateToProps, { setAlert })(StepsMenu))