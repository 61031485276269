import React, {useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames';
import AccItem from './AccItem';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as types from '../../../actions/types';
import { store } from './../../../store';
import { getAccounts } from '../../../actions/accounts';
import Spinner from '../../layout/spinner';

const StepAccSelect = (props) => {
    const getAccounts = props.getAccounts;
    const loading = props.accData.loading;
    useEffect(() => {
       if (loading) getAccounts();
    }, [loading, getAccounts]);


    const onSelectGoal = (e) => {
        const key = parseInt(e.currentTarget.dataset.key);
        store.dispatch({
            type: types.SELECT_GOAL,
            payload: key
        })
    }
    const goalIndex = props.accData.goals.find(goal => goal.current) ? props.accData.goals.find(goal => goal.current).id : null;
    return props.accData.loading?<Spinner />:<div id="stepContainer" className="stepContainer has-text-centered">
        <h3 style={{ marginBottom: '15px' }}>What type of account would you like to open?</h3>

        <div className="goals">
            <span>Your goals:</span>
            <div className="goals-container">
                <span onClick={onSelectGoal} data-key={0} className={classNames({ 'button': true, 'is-selected': !props.accData.goals.find(goal => goal.current) })}>All</span>
                <div className="buttons has-addons" style={{
                    display: 'inline-flex'
                }}>
                    {
                        props.accData.goals.map((goal) => {

                            return (
                                <span onClick={onSelectGoal} key={goal.id} data-key={goal.id} className={classNames({ 'button': true, 'is-selected': goal.current })}>
                                    {props.accData.accountTypes.filter(account => { return account.selected && account.accsToAdd.filter(acctoAdd => { return acctoAdd.goalID === goal.id }).length }).length ? <i className="far fa-check"></i> : ''}
                                    {goal.name}
                                </span>
                            )
                        })
                    }
                </div>
            </div>
        </div>

        <div className="columns is-centered accList is-mobile is-multiline" >
            {
                props.accData.accountTypes.filter(account => !account.hidden || (account.selected && account.accsToAdd.find(accToAdd => accToAdd.goalID === goalIndex))).map(account => {
                    return (
                        <AccItem key={account.id} goals={props.accData.goals} acc={account} />
                    )
                })
            }
        </div>

        <div className="has-text-left">
            <span data-target="#myModal" onClick={() => store.dispatch({ type: types.SET_MODAL, payload: { title: 'Account Type Help', saveButton: false, content: 'No problem! You may click on the blue <i style="color:#719CF0" class="fas fa-info-circle"></i> buttons beside each account type to learn more about the different types of accounts. Typically, the type of account you open depends on the goal for which you are opening the account. In general, if your goal is:<br/> <ul> <li>Retirement: Open an RRSP, Spousal RRSP, LIRA (if transferring in from a locked-in retirement account at another institution or an employer-sponsored plan such as a pension plan or DPSP) or a TFSA</li> <li>Education: Open an RESP</li> <li>Major Purchase: Open a TFSA or Non-Registered Account</li> <li>Other: Open a TFSA or Non-Registered Account</li> </ul> If you have any questions, please contact your Investment Advisor at <a href="mailto:email@domain.ca?INV=807f1f56-1775">email@domain.ca</a>' } })} className="modal-button is-link" style={{ color: '#2E63CC' }}>
                <i className="fas fa-question-circle"></i> I don’t know what type of account to open
            </span>
        </div>
    </div>
}

StepAccSelect.propTypes = {
    getAccounts: PropTypes.func.isRequired,
    accData: PropTypes.object.isRequired    
}

const mapStateToProps = state => ({
    accData: state.accData
})

export default withRouter(connect(mapStateToProps, { getAccounts })(StepAccSelect))