//alerts, modals
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_MODAL = 'SET_MODAL';
export const REMOVE_MODAL = 'REMOVE_MODAL';

//StepAccSelect
export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const TOGGLE_ACCOUNT = 'TOGGLE_ACCOUNT';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
export const SELECT_ACCOUNT = 'SELECT_ACCOUNT';
export const SELECT_ACCOUNT_GOAL = 'SELECT_ACCOUNT_GOAL';
export const SELECT_ACCOUNT_JOINT = 'SELECT_ACCOUNT_JOINT';
export const SELECT_ACCOUNT_SOURCE = 'SELECT_ACCOUNT_SOURCE';
export const SELECT_GOAL = 'SELECT_GOAL';

//StepAbout
export const UPDATE_FIELD = 'UPDATE_FIELD';
export const UPDATE_EXPERIENCE = 'UPDATE_EXPERIENCE';
export const UPDATE_EXPERIENCE_STATE = 'UPDATE_EXPERIENCE_STATE';
export const UPDATE_TAX_RESIDENCY = 'UPDATE_TAX_RESIDENCY';
export const UPDATE_BORROWED = 'UPDATE_BORROWED';
export const UPDATE_MAILING = 'UPDATE_MAILING';
export const UPDATE_INSIDER = 'UPDATE_INSIDER';
export const UPDATE_TRHIRDPARTY = 'UPDATE_TRHIRDPARTY';
export const UPDATE_CONFIRMATIONS = 'UPDATE_CONFIRMATIONS';
export const UPDATE_AFFILIATIONS_CHECKBOXES = 'UPDATE_AFFILIATIONS_CHECKBOXES';

//StepJoint
export const UPDATE_FIELD_JOINT = 'UPDATE_FIELD_JOINT';
export const UPDATE_AFFILIATIONS_CHECKBOXES_JOINT = 'UPDATE_AFFILIATIONS_CHECKBOXES_JOINT';
export const UPDATE_INSIDER_JOINT = 'UPDATE_INSIDER_JOINT';
export const UPDATE_MAILING_JOINT = 'UPDATE_MAILING_JOINT';
export const UPDATE_TAX_RESIDENCY_JOINT = 'UPDATE_TAX_RESIDENCY_JOINT';
export const SET_ACTIVE_JOINT = 'SET_ACTIVE_JOINT';
export const ADD_JOINT = 'ADD_JOINT';
export const DELETE_JOINT = 'DELETE_JOINT';

//StepBeneficiaries
export const UPDATE_BENEFICIARY = 'UPDATE_BENEFICIARY';
export const DELETE_BENEFICIARY = 'DELETE_BENEFICIARY';
export const UPDATE_BENEFICIARIES_PERCENTAGES = 'UPDATE_BENEFICIARIES_PERCENTAGES';

//StepBanking
export const UPDATE_FIELD_BANKING = 'UPDATE_FIELD_BANKING';
export const UPDATE_CONTRIBUTION = 'UPDATE_CONTRIBUTION';
export const UPDATE_TRANSFER = 'UPDATE_TRANSFER';
export const DELETE_CONTRIBUTION = 'DELETE_CONTRIBUTION';
export const DELETE_TRANSFER = 'DELETE_TRANSFER';

//Miscellaneous
export const CLEAR_STATE = 'CLEAR_STATE';