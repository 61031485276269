import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setAlert } from '../../actions/alert';

const classNames = require('classnames');


const BottomNavigation = (props) => {
    const nextPageItem = (currentPage) => {
        const currentIndex = menuitems.findIndex(x=>x['0']===currentPage);
        return (menuitems.length-1>currentIndex)? ( menuitems[currentIndex+1][0]+(menuitems[currentIndex+1][1]?`#${menuitems[currentIndex+1][1]}`:'') ) :false;
    }
    const previousPageItem = (currentPage) => {
        const currentIndex = menuitems.findIndex(x=>x['0']===currentPage);
        return (currentIndex>0)? ( menuitems[currentIndex-1][0]+(menuitems[currentIndex-1][1]?`#${menuitems[currentIndex-1][1]}`:'') ) :false;
    }

    const menuitems = [['/'], ['/stepAbout','personal']];
    if (props.accData.accountTypes.filter(account => { return account.selected && account.accsToAdd.filter(acctoAdd => { return acctoAdd.isJoint }).length }).length) menuitems.push(['/stepJoint', 'personal.0']);
    if (props.accData.accountTypes.filter(account => account.id !== 6 && account.selected).length > 0) menuitems.push(['/stepBeneficiaries']);
     menuitems.push(['/stepBanking']);
    if (props.accData.accountTypes.filter(account => account.id === 6 && account.selected).length > 0) menuitems.push(['/stepVerification']);
    

    let previousPage = previousPageItem(props.history.location.pathname);
    let nextPage = nextPageItem(props.history.location.pathname);
    let cantContinueMessage = 'Please complete the form first';

    switch (props.history.location.pathname) {
        case '/':
            if (!((typeof props.accData!=='undefined') && !props.accData.loading && props.accData.accountTypes.filter(acc => acc.selected).length)) nextPage = false;
            cantContinueMessage = 'Please select an account to open first';
        break;    
        case '/stepAbout':
        break;
        case '/stepJoint':
        break;
        case '/stepBeneficiaries':
        break;
        case '/stepBanking':
        break;
        case '/stepVerification':
            previousPage = '/stepBanking';
        break;
        default: break;
    }
    

    return <div className="sbottom">
        <button  onClick={() => {if (previousPage)  props.history.push(previousPage);  }} className={classNames({'button iaButton bg-gray':true, 'disabled':previousPage===false })} disabled={previousPage===false}>
            <i className="fas fa-caret-left"></i>
            Back
        </button>
        {menuitems.findIndex(x=>x['0']===props.history.location.pathname)<menuitems.length-1 && props.history.location.pathname !=='/stepVerification' &&
        <button onClick={() => {
            if (!nextPage) {
                props.setAlert(cantContinueMessage, 'is-danger', 3000);
            } else {
                props.history.push(nextPage);
            }
        }} className={classNames({ 'button iaButton bg-gray': true, 'is-selected': nextPage })}>
            Continue <i className="fas fa-caret-right"></i>
        </button>
        }

        {menuitems.findIndex(x=>x['0']===props.history.location.pathname)===menuitems.length-1 && props.history.location.pathname ==='/stepBanking' &&
        <button onClick={() => {
            props.history.push('/stepVerification');
        }} className={classNames({ 'button iaButton bg-gray': true, 'is-selected': false })}>
            Submit
        </button>
        }
        
    </div>
}

BottomNavigation.propTypes = {
    accData: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    accData: state.accData,
    aboutJoint: state.aboutJoint.jointsArray
})

export default withRouter(connect(mapStateToProps,  { setAlert } )(BottomNavigation))