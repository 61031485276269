import React, { useRef, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import * as types from './../../../actions/types'
import { store } from './../../../store'
import TabsComponent from './../../../utils/tabsComponent'
import Employment from './Employment'
import AccordionComponent from './../../../utils/accordionComponent'
import InputMask from 'react-input-mask'
import { connect } from 'react-redux'
import TaxResidency from './TaxResidency'
import AffiliationsQuestion from './AffiliationsQuestion'
import Borrowed from './Borrowed'
import Insider from './Insider'
import ThirdParty from './ThirdParty'
import Confirmations from './Confirmations'
import MailingDifferent from './MailingDifferent.js'
import classNames from 'classnames';
import PropTypes from 'prop-types';
/*import { setAlert } from '../../../actions/alert';*/
import { countryList, camelToSentence } from '../../../utils/misc';


//import PropTypes from 'prop-types'


const StepAbout = (props) => {
    const taxResidencyRef = useRef({});
    const mailingDifferentRef = useRef({});
    const borrowedRef = useRef({});
    const insiderRef = useRef({});
    const thirdPartyRef = useRef({});
    const confirmationsRef = useRef({});

    const { history, about, updateFields, updateExperience, updateExperienceState, updateAffiliationsCheckboxes } = props;
    const { title, firstName, middleName, lastName, dob, maritalStatus, dependents, sin, countryCitizenship, otherTaxResident, otherTaxResidentCountryList, borrowedList, insiderList, thirdPartyList, confirmations, experienceYears, spouse, email, phone, unit, street, city, province, postalCode, country, mailingDifferent, mailingAddress, annualIncome, status, employersName, occupation, typeOfBusiness, investmentExperience, investmentExperienceState, affiliations, disclosure, language, securityholder, duplicate } = about;

    const showResidency = () => store.dispatch({ type: types.SET_MODAL, payload: { title: 'Tax residency info', saveButton: saveOtherTaxResidentCountryList, content: <TaxResidency stateRef={taxResidencyRef} parentCountryList={otherTaxResidentCountryList} /> } })
    const showMailing = () => store.dispatch({ type: types.SET_MODAL, payload: { title: 'Mailing address', saveButton: saveMailing, content: <MailingDifferent stateRef={mailingDifferentRef} parentMailingAddress={mailingAddress} /> } })
    const showBorrowed = () => store.dispatch({ type: types.SET_MODAL, payload: { title: 'Loan details', saveButton: saveBorrowed, content: <Borrowed stateRef={borrowedRef} parentBorrowedList={borrowedList} /> } })
    const showInsider = () => store.dispatch({ type: types.SET_MODAL, payload: { title: ' Insider and/or Control Person details', saveButton: saveInsider, content: <Insider stateRef={insiderRef} parentInsiderList={insiderList} /> } })
    const showThirdPArty = () => store.dispatch({ type: types.SET_MODAL, payload: { title: 'Third party details', saveButton: saveThirdParty, content: <ThirdParty stateRef={thirdPartyRef} parentThirdPartyList={thirdPartyList} /> } })
    const showConfirmations = () => store.dispatch({ type: types.SET_MODAL, payload: { title: 'Duplicate Confirmations', saveButton: saveConfirmations, content: <Confirmations stateRef={confirmationsRef} parentConfirmations={confirmations} /> } })

    const saveOtherTaxResidentCountryList = () => { return taxResidencyRef.current.validate() ? props.updateTaxResidency(taxResidencyRef) : false; }
    const saveBorrowed = () => { return borrowedRef.current.validate() ? props.updateBorrowed(borrowedRef) : false; }
    const saveInsider = () => { return insiderRef.current.validate() ? props.updateInsider(insiderRef) : false; }
    const saveMailing = () => { return mailingDifferentRef.current.validate() ? props.updateMailing(mailingDifferentRef) : false; }
    const saveThirdParty = () => { return thirdPartyRef.current.validate() ? props.updateThirdParty(thirdPartyRef) : false; }
    const saveConfirmations = () => { return confirmationsRef.current.validate() ? props.updateConfirmations(confirmationsRef) : false; }

    const tabsClient = [{
        name: "Personal Information",
        anchor: "personal",
        content: <Fragment>
            <div className="columns is-centered is-mobile is-multiline is-fullwidth topcolumns">
                <div className="column is-half-mobile is-one-third-tablet is-one-third-desktop">
                    <div className="field">
                        <div className="columns">
                            <div className="column is-narrow"><div className="select is-fullwidth"><select onChange={updateFields} value={title} required name="title"><option value="">Title</option><option value="Mr.">Mr.</option> <option value="Mrs.">Mrs.</option> <option value="Ms.">Ms.</option> <option value="Dr.">Dr.</option></select></div></div>
                            <div className="column"><input onChange={updateFields} value={firstName} className="input" name="firstName" type="text" required placeholder="First Name" /></div>
                        </div>
                    </div>
                    <div className="field"> <input onChange={updateFields} value={middleName} className="input" name="middleName" type="text" placeholder="Middle Name" /> </div>
                    <div className="field"> <input onChange={updateFields} value={lastName} className="input" name="lastName" type="text" required placeholder="Last Name" /> </div>
                </div>
                <div className="column is-half-mobile is-one-third-tablet is-one-third-desktop">
                    <div className="field">
                        <label htmlFor="dob" className="label">Date of birth</label>
                        <p className="control has-icons-left">
                            <input onChange={updateFields} value={dob} className="input" name="dob" id="dob" required type="date" placeholder="mm/dd/yyyy" />
                            <span className="icon is-left"><i className="fas fa-calendar-alt"></i></span>
                        </p>
                    </div>

                    <div className="field">
                        <label htmlFor="maritalStatus" className="label">Marital Status</label>
                        <div className="control has-icons-left">
                            <div className="select has-icons-left is-fullwidth"><select onChange={updateFields} value={maritalStatus} required name="maritalStatus" id="maritalStatus"><option value="">Marital Status</option><option value="Single">Single</option> <option value="Married">Married</option> <option value="Common-law">Common-law</option> <option value="Divorced">Divorced</option>  <option value="Widowed">Widowed</option> </select> <span className="icon is-left"><i className="fas fa-ring"></i></span> </div>
                        </div>
                    </div>

                    <div className="field label-right"> <input onChange={updateFields} value={dependents} className="input" id="dependents" name="dependents" min="0" type="number" /> <label className="label" htmlFor="dependents">Number of dependents</label></div>
                </div>
                <div className="column is-full-mobile is-one-third-tablet is-one-third-desktop">
                    <div className="field">
                        <label htmlFor="sin" className="label">Social Insurance Number</label>
                        <p className="control has-icons-left has-icons-right">
                            <InputMask onChange={updateFields} value={sin} className="input" name="sin" id="sin" type="text" required placeholder="111-111-111" mask="999-999-999" maskChar="" />
                            <span onClick={() => store.dispatch({ type: types.SET_MODAL, payload: { title: 'SIN info', saveButton: false, content: '<strong>Why do I have to provide my SIN?</strong> Because you may earn interest or other income in your account, we are required to use your SIN to report this income to the government. For more information on who can and cannot ask you to provide your SIN, please review the following link: <a href="http://www.servicecanada.gc.ca/eng/sin/protect/provide.shtml?INV=807f1f56-1775" target="_blank">www.servicecanada.gc.ca/eng/sin/protect/provide.shtml</a>' } })} className="icon is-right has-actions"><i style={{ color: '#719CF0' }} className="fas fa-info-circle"></i></span>
                            <span className="icon is-left"><i className="fas fa-id-card"></i></span>
                        </p>
                    </div>
                    <div className="field">
                        <label htmlFor="countryCitizenship" className="label">Country of citizenship</label>
                        <div className="control has-icons-left">
                            <div className="select has-icons-left is-fullwidth">
                                <select onChange={updateFields} value={countryCitizenship} required name="countryCitizenship" id="countryCitizenship">
                                    <option value="">Country</option>
                                    {countryList.map((val, index) => {
                                        return <option key={index} value={val}>{val}</option>
                                    })}
                                </select>
                                <span className="icon is-left"><i className="fas fa-globe"></i></span>
                            </div>
                        </div>
                    </div>
                    <div className="field">
                        <div className="columns reversed">
                            <div className="column is-narrow">
                                <div className={classNames({ "control has-actions": true, "has-icons-left": otherTaxResident === 'yes' })}>
                                    <div className="select is-fullwidth">
                                        <select onChange={(e) => { if (e.target.value === 'yes') showResidency(); updateFields(e); }} value={otherTaxResident} name="otherTaxResident" id="otherTaxResident"><option value={'no'}>No</option><option value={'yes'}>Yes</option></select>
                                    </div>
                                    {otherTaxResident === 'yes' && <span onClick={() => showResidency()} className="icon is-left has-actions"><i style={{ color: '#3273dc' }} title="Edit" className="fas fa-edit"></i></span>}
                                </div>
                            </div>
                            <div className="column labelonly">
                                <label className="label" htmlFor="otherTaxResident"><span>Are you a tax resident of a country other than Canada?</span>{otherTaxResident === 'yes' && <span onClick={() => showResidency()} className="is-link">Edit</span>} </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >,
        icon: 'fas fa-user'
    },
    {
        name: "Contact Details",
        anchor: "contact",
        content: <Fragment>
            <div style={{ paddingTop: '1rem' }} className="columns is-mobile is-multiline is-fullwidth topcolumns">
                <div className="column is-half-mobile is-half-tablet is-one-third-desktop">
                    <div className="field">
                        <label htmlFor="email" className="label">Email address</label>
                        <p className="control has-icons-left">
                            <input onChange={updateFields} value={email} className="input" name="email" id="email" required type="text" placeholder="email@domain.com" />
                            <span className="icon is-left"><i className="fas fa-at"></i></span>
                        </p>
                    </div>

                    <div className="field">
                        <label htmlFor="phone" className="label">Phone number</label>
                        <p className="control has-icons-left">
                            <InputMask onChange={updateFields} value={phone} className="input" name="phone" id="phone" required type="text" placeholder="1 (111) 111-1111" mask="9 (999) 999-99-99" maskChar="" />
                            <span className="icon is-left"><i className="fas fa-phone"></i></span>
                        </p>
                    </div>
                </div>
                <div className="column is-half-mobile is-half-tablet is-two-thirds-desktop">
                    <h4 style={{ marginBottom: '1.5rem', marginTop: '-3.9rem', textAlign: 'center', color: '#363636', paddingTop: '0px' }}>Residential or legal address</h4>
                    <div className="columns field">
                        <div className="column is-narrow">
                            <label htmlFor="unit" className="label">Unit</label>
                            <p className="control">
                                <input onChange={updateFields} value={unit} className="input" name="unit" id="unit" type="text" placeholder="" />
                            </p>
                        </div>
                        <div className="column">
                            <label htmlFor="street" className="label">Street</label>
                            <p className="control">
                                <input onChange={updateFields} value={street} className="input" name="street" id="street" type="text" placeholder="" />
                            </p>
                        </div>
                    </div>


                    <div className="columns field">
                        <div className="column is-4">
                            <label htmlFor="city" className="label">City</label>
                            <p className="control">
                                <input onChange={updateFields} value={city} className="input" name="city" id="city" type="text" placeholder="" />
                            </p>
                        </div>

                        <div className="column is-4">
                            <label htmlFor="province" className="label">Province</label>
                            <p className="control">
                                <input onChange={updateFields} value={province} className="input" name="province" id="province" type="text" placeholder="" />
                            </p>
                        </div>

                        <div className="column is-4">
                            <label htmlFor="postalCode" className="label">Postal Code</label>
                            <p className="control">
                                <input onChange={updateFields} value={postalCode} className="input" name="postalCode" id="postalCode" type="text" placeholder="" />
                            </p>
                        </div>
                    </div>


                    <div className="columns field">
                        <div className="column is-half-tablet">
                            <label htmlFor="country" className="label">Country</label>
                            <div className="control has-icons-left">
                                <div className="select has-icons-left is-fullwidth">
                                    <select onChange={updateFields} value={country} required name="country" id="country">
                                        <option value="">Country</option>
                                        {countryList.map((val, index) => {
                                            return <option key={index} value={val}>{val}</option>
                                        })}
                                    </select>
                                    <span className="icon is-left"><i className="fas fa-globe"></i></span>
                                </div>
                            </div>
                        </div>
                        <div className="column is-half-tablet">
                            <div className="field">
                                <div className="columns reversed">
                                    <div className="column is-narrow">
                                        <div className={classNames({ "control has-actions": true, "has-icons-left": mailingDifferent === 'yes' })}>
                                            <div className="select is-fullwidth">
                                                <select onChange={(e) => { if (e.target.value === 'yes') showMailing(); updateFields(e); }} value={mailingDifferent} name="mailingDifferent" id="mailingDifferent"><option value={'no'}>No</option><option value={'yes'}>Yes</option></select>
                                            </div>
                                            {mailingDifferent === 'yes' && <span onClick={() => showMailing()} className="icon is-left has-actions"><i style={{ color: '#3273dc' }} title="Edit" className="fas fa-edit"></i></span>}
                                        </div>

                                    </div>
                                    <div className="column labelonly">
                                        <label className="label" htmlFor="mailingDifferent"><span>Is mailing address different from above?</span>{mailingDifferent === 'yes' && <span onClick={() => showMailing()} className="is-link">Edit</span>} </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Fragment>,
        icon: 'fas fa-envelope-open'
    },
    {
        name: <Fragment>Employment <span className='is-hidden-tablet-only is-hidden-mobile'>Information</span></Fragment>,
        anchor: "employment",
        content: <Employment prefix="" annualIncome={annualIncome} status={status} employersName={employersName} occupation={occupation} typeOfBusiness={typeOfBusiness} updateFields={updateFields} />,
        icon: 'fas fa-briefcase'
    },
    {
        name: "Investment Experience",
        anchor: "investment",
        content: <Fragment>
            <div className="tabWrapper">
                <div className=""><div className="field label-right"> <input onChange={updateFields} style={{ maxWidth: '60px' }} value={experienceYears} className="input" id="experienceYears" name="experienceYears" min="0" type="number" /> <label className="label" htmlFor="experienceYears">How many years of investment experience do you have?</label></div></div>
                {
                    experienceYears > -1 &&
                    <div className="experienceWrapper">
                        <div className="experienceHeader">
                            <label className="label">Type of investment experience:</label>
                            <div className="experienceButtons">
                                {
                                    Object.keys(investmentExperience).map((key) => {
                                        return <span key={key} onClick={() => updateExperienceState(key)} className={classNames({ "is-link": true, "is-active": investmentExperienceState === key })}>{camelToSentence(key)}</span>
                                    })
                                }
                            </div>
                        </div>
                        <ul>
                            {
                                investmentExperience[investmentExperienceState].map((investment, index) => {
                                    return <li key={index}><label className="checkbox"><input data-key={index} onChange={updateExperience} checked={investment.checked} type="checkbox" />{investment.title}</label></li>
                                })
                            }
                        </ul>
                    </div>

                }
            </div>
        </Fragment>,
        icon: 'fas fa-chart-line'
    }
    ]

    const tabsSpouse = [{
        name: "Personal Information",
        anchor: "personalSpouse",
        content: <Fragment>
            <div className="columns is-centered is-mobile is-multiline is-fullwidth topcolumns">
                <div className="column is-half-mobile">
                    <div className="field">
                        <div className="columns">
                            <div className="column is-narrow"><div className="select is-fullwidth"><select onChange={updateFields} value={spouse.title} required name="spouse.title"><option value="">Title</option><option value="Mr.">Mr.</option> <option value="Mrs.">Mrs.</option> <option value="Ms.">Ms.</option> <option value="Dr.">Dr.</option></select></div></div>
                            <div className="column"><input onChange={updateFields} value={spouse.firstName} className="input" name="spouse.firstName" required type="text" placeholder="First Name" /></div>
                        </div>
                    </div>
                    <div className="field"> <input onChange={updateFields} value={spouse.middleName} className="input" name="spouse.middleName" type="text" placeholder="Middle Name" /> </div>
                    <div className="field"> <input onChange={updateFields} value={spouse.lastName} className="input" name="spouse.lastName" required type="text" placeholder="Last Name" /> </div>
                </div>
                <div className="column is-half-mobile">
                        <div className="field doubleLayer">
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label htmlFor="spouse.dob" className="label">Date of birth</label>
                                        <p className="control has-icons-left">
                                            <input onChange={updateFields} value={spouse.dob} className="input" name="spouse.dob" id="spouse.dob" required type="date" placeholder="mm/dd/yyyy" />
                                            <span className="icon is-left"><i className="fas fa-calendar-alt"></i></span>
                                        </p>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label htmlFor="spouse.sin" className="label">Social Insurance Number</label>
                                        <p className="control has-icons-left has-icons-right">
                                            <InputMask onChange={updateFields} value={spouse.sin} className="input" name="spouse.sin" id="spouse.sin" type="text" required placeholder="111-111-111" mask="999-999-999" maskChar="" />
                                            <span onClick={() => store.dispatch({ type: types.SET_MODAL, payload: { title: 'SIN info', saveButton: false, content: '<strong>Why do I have to provide SIN?</strong> Because you may earn interest or other income in your account, we are required to use your SIN to report this income to the government. For more information on who can and cannot ask you to provide your SIN, please review the following link: <a href="http://www.servicecanada.gc.ca/eng/sin/protect/provide.shtml?INV=807f1f56-1775" target="_blank">www.servicecanada.gc.ca/eng/sin/protect/provide.shtml</a>' } })} className="icon is-right has-actions"><i style={{ color: '#719CF0' }} className="fas fa-info-circle"></i></span>
                                            <span className="icon is-left"><i className="fas fa-id-card"></i></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div className="field">
                        <label htmlFor="spouse.email" className="label">Email address</label>
                        <p className="control has-icons-left">
                            <input onChange={updateFields} value={spouse.email} className="input" name="spouse.email" id="spouse.email" required type="text" placeholder="email@domain.com" />
                            <span className="icon is-left"><i className="fas fa-at"></i></span>
                        </p>
                    </div>

                    <div className="field">
                        <label htmlFor="spouse.phone" className="label">Phone number</label>
                        <p className="control has-icons-left">
                            <InputMask onChange={updateFields} value={spouse.phone} className="input" name="spouse.phone" id="spouse.phone" required type="text" placeholder="1 (111) 111-1111" mask="9 (999) 999-99-99" maskChar="" />
                            <span className="icon is-left"><i className="fas fa-phone"></i></span>
                        </p>
                    </div>
                </div>
            </div>
        </Fragment >,
        icon: 'fas fa-user'
    },
    {
        name: <Fragment>Employment Information</Fragment>,
        anchor: "employmentSpouse",
        content: <Employment prefix="spouse." annualIncome={spouse.annualIncome} status={spouse.status} employersName={spouse.employersName} occupation={spouse.occupation} typeOfBusiness={spouse.typeOfBusiness} updateFields={updateFields} />,
        icon: 'fas fa-briefcase'
    }
    ]

    const affiliationsClient = <div className="accordionContentWrapper">
        <div className="columns field is-centered is-mobile is-multiline is-fullwidth topcolumns affiliationsTab">
            {
                [0, 1].map((column) => {
                    return <div key={column} className="column is-full-mobile is-half-tablet">
                        {Object.keys(affiliations).slice(column * Math.ceil(Object.keys(affiliations).length / 2), Math.ceil(Object.keys(affiliations).length * ((column + 1) / 2))).map((key) => {
                            return <AffiliationsQuestion key={key} index={key} updateFields={updateFields} updateAffiliationsCheckboxes={(e)=>{updateAffiliationsCheckboxes(e); if (e.target.name==='affiliations.thirdParty.detailsCheckboxes.0' && e.target.checked) showThirdPArty();  }} popupAction={{ 'borrowedMoney': showBorrowed,  'thirdParty': showThirdPArty, 'controlPerson': showInsider }[key]} question={affiliations[key]} />
                        })}
                    </div>
                })
            }
        </div>
        <div style={{ marginBottom: '-0.7rem' }} className="clear"></div>
    </div>

    const shareholderInstruction = <div className="accordionContentWrapper">
        <div style={{marginBottom:'3.7rem', marginTop:'-1rem'}} className="columns field is-mobile is-multiline is-fullwidth topcolumns">
            <div className="column is-full-mobile">
                Once your account has been opened, you will be able to view your portfolio, transactions, and statements on your secure online portal. You will also receive communication from *company_name*. In the following section, you will be asked about how you would like *company_name* to communicate with you regarding information about your account, and updates or bulletins provided by the managers of the individual funds that your representative has included in your portfolio.
                <hr style={{marginBottom:'3.7rem'}}/>
                <div className="field">
                    <label className="label">Disclosure of Beneficial Ownership Information <span onClick={() => store.dispatch({ type: types.SET_MODAL, payload: { title: ' Disclosure of Beneficial Ownership Information', saveButton: false, content: 'What is Beneficial Ownership Information? This is information about you, the account holder, that National Branch will provide on a confidential basis to the issuers of the securities (the fund companies whose ETFs and/or mutual funds you will hold) that has included in your account. This information is provided so that these companies can communicate with you directly, on an as-needed basis. This is NOT marketing or sales material; it is information that they are required to provide to you under securities law. We recommend you DO NOT OBJECT to National Branch disclosing this information.<br/><br/>Please mark the corresponding box to show whether you DO NOT OBJECT or whether you do OBJECT to disclosing your name, address, electronic mail address, securities holdings and preferred language of communication (English or French) to issuers of securities you hold National Branch and to other persons or companies in accordance with securities law. If you indicate that you OBJECT, security holder materials that are required to be sent to you under securities law will be sent by National Branch and a fee per delivery may be charged to your account with National Branch.' } })} className="icon is-link is-right has-actions"><i style={{ color: '#719CF0' }} className="fas fa-info-circle"></i></span></label>
                    <div style={{marginTop:'10px'}} className="control">
                        <label className="radio">
                            <input type="radio" value="0" onChange={updateFields} checked={parseInt(disclosure)===0} name="disclosure"/>
                            I DO NOT OBJECT to your disclosing the information described above.
                        </label>
                        <label className="radio">
                            <input type="radio"  value="1" onChange={updateFields} checked={parseInt(disclosure)===1} name="disclosure"/>                        	
                            I OBJECT to you disclosing the information described above. I understand that required material will be delivered to me by a reporting issuer or other person or company that is entitled to send these materials to me at its expense.
                        </label>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Receiving Securityholder Materials <span onClick={() => store.dispatch({ type: types.SET_MODAL, payload: { title: 'Receiving Securityholder Materials', saveButton: false, content: 'What are Securityholder Materials? These are materials that the fund companies will send to you to notify you of annual and special meetings, financial statements, and other material that they may be required to send under securities law. Proxy-related material is material that transfers your rights as a shareholder in these funds to the majority shareholder (or management) of the funds to vote on company matters at their discretion, but on your behalf. Most of our clients DECLINE to receive this material, but the choice is yours.<br/><br/>Please mark the corresponding box to show what materials you want to receive. Securityholder materials sent to beneficial owners of securities consist of the following materials: (a) proxy-related materials for annual and special meetings; (b) annual reports and financial statements that are not part of proxy-related materials; and (c) materials sent to securityholders that are not required by corporate or securities law to be sent.' } })} className="icon is-link is-right has-actions"><i style={{ color: '#719CF0' }} className="fas fa-info-circle"></i></span></label>
                    <div style={{marginTop:'10px'}} className="control">
                        <label className="radio">
                            <input type="radio" value="1" onChange={updateFields} checked={parseInt(securityholder)===1} name="securityholder"/>
                            I WANT to receive ALL Securityholder materials sent to beneficial owners of securities.
                        </label>
                        <label className="radio">
                            <input type="radio" value="0" onChange={updateFields} checked={parseInt(securityholder)===0} name="securityholder"/>                        	
                            I DECLINE to receive ALL Securityholder materials sent to beneficial owners of securities.
                        </label>
                        <label className="radio">
                            <input type="radio" value="2" onChange={updateFields} checked={parseInt(securityholder)===2} name="securityholder"/>                        	
                            I WANT to receive ONLY proxy-related materials that are sent in connection with a special meeting.
                        </label>
                    </div>
                </div>
                
                <div className="columns is-mobile">
                    <div className="column is-half-mobile">
                        <div className="field">
                            <label className="label" htmlFor="duplicate">Preferred Language of Communication <span onClick={() => store.dispatch({ type: types.SET_MODAL, payload: { title: 'Preferred Language of Communication', saveButton: false, content:'I understand that the materials I receive will be in my preferred language of communication if the materials are available in that language.' } })} className="icon is-link is-right has-actions"><i style={{ color: '#719CF0' }} className="fas fa-info-circle"></i></span></label>
                            <div className="select is-fullwidth"><select onChange={updateFields} value={language} required name="language"><option value="en">English</option> <option value="fr">French</option> </select></div>
                        </div>
                    </div>
                    <div className="column is-half-mobile">
                        <div className="field">
                            <label className="label" htmlFor="duplicate">Duplicate Confirmations&nbsp;
                                {(parseInt(duplicate) === 1) && <span style={{marginRight:'5px'}} onClick={() => showConfirmations()} className="is-link">Edit</span>}
                                <span onClick={() => store.dispatch({ type: types.SET_MODAL, payload: { title:'Duplicate Confirmations', saveButton: false, content:'Would you like to have duplicates of transaction confirmations and/or account statements sent to another person in additional to the materials that you will receive? <br/><br/>Why would I want duplicate mailings? You may want another person to receive copies of your statements, such as your spouse, or your personal representative (a lawyer or the executor of your will). This feature is also useful if you are managing investments for an elderly parent, in which case you can ask them to have duplicate mailings to you.' } })} className="icon is-link is-right has-actions"><i style={{ color: '#719CF0' }} className="fas fa-info-circle"></i></span>
                            </label>
                                
                            <div className={classNames({ "control has-actions": true, "has-icons-left":  parseInt(duplicate) === 1 })}>
                                <div className="select is-fullwidth">
                                    <select onChange={(e) => { if (parseInt(e.target.value) === 1) showConfirmations(); updateFields(e); }} value={duplicate} name="duplicate" id="duplicate"><option value={0}>No</option><option value={1}>Yes</option></select>
                                </div>
                                {parseInt(duplicate) === 1 && <span onClick={() => showConfirmations()} className="icon is-left has-actions"><i style={{ color: '#3273dc' }} title="Edit" className="fas fa-edit"></i></span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    const accordiontabs = [{ title: 'Client information', anchor: 'personal', content: <TabsComponent activeTab="1" tabs={tabsClient} /> }]
    if (['Married', 'Common-law'].includes(maritalStatus)) accordiontabs.push({ title: (maritalStatus === 'Married' ? 'Spouse' : 'Partner') + ' information', anchor: 'personalSpouse', content: <TabsComponent tabs={tabsSpouse} /> });
    accordiontabs.push({ title: 'Affiliations', anchor: 'affiliationsClient', content: affiliationsClient });
    accordiontabs.push({ title: 'Shareholder communication instruction', anchor: 'shareholderInstruction', content: shareholderInstruction });

    let accordionIndex = -1; 
    if (history.location.hash === '#personal') accordionIndex=0; else if ((['Married', 'Common-law'].includes(maritalStatus)) && tabsSpouse.find(tab => '#' + tab.anchor === history.location.hash)) accordionIndex = 1; else if (history.location.hash === '#affiliationsClient') accordionIndex = (['Married', 'Common-law'].includes(maritalStatus)) ? 2 : 1;  else if (history.location.hash === '#shareholderInstruction') accordionIndex = (['Married', 'Common-law'].includes(maritalStatus)) ? 3 : 2;

    return <div className="stepContainer" id="stepContainer">
        <AccordionComponent activeTab={accordionIndex} tabs={accordiontabs} />
    </div >;
}


StepAbout.propTypes = {
    accData: PropTypes.object.isRequired,
    about: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    accData: state.accData,
    about: state.about
})

const mapDispatchToProps = dispatch => ({
    updateFields: e => {
        return dispatch({
            type: types.UPDATE_FIELD,
            target: e.target
        })
    },
    updateAffiliationsCheckboxes: e => dispatch({
        type: types.UPDATE_AFFILIATIONS_CHECKBOXES,
        target: e.target
    }),
    updateExperience: e => dispatch({
        type: types.UPDATE_EXPERIENCE,
        target: e.target
    }),
    updateExperienceState: key => dispatch({
        type: types.UPDATE_EXPERIENCE_STATE,
        key: key
    }),
    updateTaxResidency: taxResidencyRef => dispatch({
        type: types.UPDATE_TAX_RESIDENCY,
        otherTaxResidentCountryList: taxResidencyRef.current.otherTaxResidentCountryList
    }),
    updateBorrowed: borrowedRef => dispatch({
        type: types.UPDATE_BORROWED,
        borrowedList: borrowedRef.current.borrowedList
    }),
    updateInsider: insiderRef => dispatch({
        type: types.UPDATE_INSIDER,
        insiderList: insiderRef.current.insiderList
    }),
    updateMailing: mailingDifferentRef => dispatch({
        type: types.UPDATE_MAILING,
        mailingAddress: mailingDifferentRef.current.mailingAddress
    }),
    updateThirdParty: thirdPartyRef => dispatch({
        type: types.UPDATE_TRHIRDPARTY,
        thirdPartyList: thirdPartyRef.current.thirdPartyList
    }),
    updateConfirmations: confirmationsRef => dispatch({
        type: types.UPDATE_CONFIRMATIONS,
        confirmations: confirmationsRef.current.confirmations
    })

});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepAbout))