import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { store } from '../store';
import * as types from '../actions/types';
import { setAlert } from '../actions/alert';

const ModalComponent = ({ modal: { content, title, saveButton, active }, setAlert }) => {

    const onSave = (e) => {
        if (typeof saveButton === 'function') {
            if (!saveButton()) return;
        }
        setAlert('Successfully saved', 'is-success', 3000);
        store.dispatch({ type: types.REMOVE_MODAL })
    }
    useEffect(() => {
        document.addEventListener("keydown", (e) => { if (e.keyCode === 27) { store.dispatch({ type: types.REMOVE_MODAL }) }  }, false);
    }, []);
    
    if (typeof content === 'object' && typeof content.$$typeof !== 'symbol') active = false;

    return <div onKeyUp={(e)=>{
        if (e.keyCode === 13 && typeof saveButton==='function') { onSave() }
    }} className={classNames({ 'modal animated fadeIn stepContainer': true, 'is-active': active })} id="myModal">
        <div className="modal-background" onClick={(e) => { store.dispatch({ type: types.REMOVE_MODAL }) }} ></div>
        <div className="modal-card animated zoomIn">
            <header className="modal-card-head">
                <p className="modal-card-title">{title}</p>
                <button className="delete" onClick={(e) => { store.dispatch({ type: types.REMOVE_MODAL }) }} aria-label="close"></button>
            </header>
            <section className="modal-card-body">
                <div className="content">
                    {typeof content !== 'object' ? <div  dangerouslySetInnerHTML={{ __html:active ? content : null }}  className="tabs-content"></div> : <div className="tabs-content">{active ? content : null}</div>}
                </div>
            </section>

            {saveButton !== false &&
                <footer className="modal-card-foot">
                    <button className="button is-success" onClick={onSave}>Save changes</button>
                    <button className="button" onClick={(e) => { store.dispatch({ type: types.REMOVE_MODAL }) }}>Cancel</button>
                </footer>
            }

        </div>
    </div>;
}

ModalComponent.propTypes = {
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    modal: state.modal
})

export default connect(mapStateToProps, { setAlert })(ModalComponent);