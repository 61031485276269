import React from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import StepAccSelect from './accountopening/StepAccSelect/StepAccSelect';
import StepAbout from './accountopening/StepAbout/StepAbout';
import stepBeneficiaries from './accountopening/stepBeneficiaries/StepBeneficiaries';
import StepBanking from './accountopening/StepBanking/StepBanking';
import StepJoint from './accountopening/StepAbout/StepJoint';
import StepVerification from './accountopening/StepVerification';
import Invisor from './Invisor';
import StepsMenu from './accountopening/StepsMenu';
import BottomNavigation from './accountopening/BottomNavigation';
import { createBrowserHistory as createHistory } from 'history'

const customHistory = createHistory();
let lastLocation = customHistory.location.pathname

const Main = (props) => {
  props.history.listen((location, action) => {
    if (lastLocation !== location.pathname)
      window.scrollTo(0, 0);
    lastLocation = location.pathname;
  });
  if (props.history.location.pathname!=='/' && (!((typeof props.accData!=='undefined') && !props.accData.loading && props.accData.accountTypes.filter(acc => acc.selected).length)) ) props.history.push('/');
  return <main>
    <StepsMenu />
    <Switch>
      <Route exact path={`${process.env.PUBLIC_URL}/`} component={StepAccSelect} />
      <Route exact path={`${process.env.PUBLIC_URL}/stepAbout`} component={StepAbout} />
      <Route exact path={`${process.env.PUBLIC_URL}/stepJoint`} component={StepJoint} />
      <Route exact path={`${process.env.PUBLIC_URL}/stepBeneficiaries`} component={stepBeneficiaries} />
      <Route exact path={`${process.env.PUBLIC_URL}/stepBanking`} component={StepBanking} />
      <Route exact path={`${process.env.PUBLIC_URL}/stepVerification`} component={StepVerification} />
      <Route exact path={`${process.env.PUBLIC_URL}/invisor`} component={Invisor} />
    </Switch>
    {!props.accData.loading && <BottomNavigation />}
  </main>

}

const mapStateToProps = state => ({
  accData: state.accData
})

export default withRouter(connect(mapStateToProps,null )(Main))