import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Slider, Rail, Handles, Tracks } from './../../../utils/slider'
import { SliderRail, Handle, Track } from './../../../utils/sliderComponents'
import {isEqual} from './../../../utils/misc'
import * as types from './../../../actions/types'


const sliderStyle = {
  position: 'relative',
  width: '100%',
  marginTop: '150px'
}

const domain = [1, 100]

class BeneficiariesSlider extends Component {
  constructor(props) {
    super(props);
    //this.updateBens = this.updateBens.bind(this);
    
    // const defaultValues = this.getValues(this.props.beneficiaries);
    this.state = {
      beneficiariesAccounts: [],
      values: []
    };
  }

  componentDidUpdate(prevProps) {
    if(!isEqual(this.props.beneficiaries, prevProps.beneficiaries))
    {
      this.updateBens();
    }
  } 

  componentDidMount() {
    this.updateBens();
  } 

  updateBens() {
    let beneficiariesAccounts = this.props.beneficiaries;

    this.props.accData.accountTypes.forEach(val => {
      if (!beneficiariesAccounts || !Array.isArray(beneficiariesAccounts) || beneficiariesAccounts.length===0) {
        beneficiariesAccounts.push(
            { relationship: 'Estate', color: '#c4c4c4', firstName: 'Estate', lastName: '', middleName: '', sin: '', dob: '', percentage: 100 }
            // {relationship:'Wife', firstName: 'Melissa', lastName: 'Smith', middleName: '', sin:'', dob:'', percentage: 20}, // {relationship:'Mother', firstName: 'Mary', lastName: 'Smith', middleName: '', sin:'', dob:'', percentage: 40}, // {relationship:'Brother', firstName: 'Mark', lastName: 'Smith', middleName: '', sin:'', dob:'', percentage: 20}, // {relationship:'Father', firstName: 'Lory', lastName: 'Smith', middleName: '', sin:'', dob:'', percentage: 10}, // {relationship:'Sister', firstName: 'Marta', lastName: 'Smith', middleName: '', sin:'', dob:'', percentage: 10}
        );
      }
    })

    const defaultValues = this.getValues(beneficiariesAccounts);

    this.setState({ beneficiariesAccounts: beneficiariesAccounts, values: defaultValues.slice() })
  }

  getValues = (beneficiariesAccounts) => {
    const returnValues = [];
    let total = 0;
    for (let index = 0; index < beneficiariesAccounts.length; index++) {
      returnValues.push(beneficiariesAccounts[index].percentage + total);
      total += beneficiariesAccounts[index].percentage;      
    }
    return returnValues;
  }

  
  onChange = values => {
    //this.setState({ values });
    let newValues = [...values];
    for (let index = 0; index < newValues.length; index++) {
      newValues[index] = {guid:this.state.beneficiariesAccounts[index].guid, percentage: (index===0)?values[index]:values[index]-values[index-1] };      
    }

    this.props.updatePercentages( newValues, this.props.account);
  }

  deleteBeneficiary = (index) => {
    this.props.deleteBen(this.state.beneficiariesAccounts[index].guid, this.props.account);
  }

  editBeneficiary = (index) => {
    this.props.updateBen(this.state.beneficiariesAccounts[index]);
  }


  editBeneficiaryPercentage = (index, e) => {
    // const re = /^[0-9\b]+$/;
    // if (e.target.value === '' || re.test(e.target.value)) {
    //    let newVal = e.target.value;
    //    const newBeneficiaries = this.state.beneficiariesAccounts.slice();
    //    newBeneficiaries[index].percentage = newVal;        
    //     this.setState({beneficiariesAccounts: newBeneficiaries, values: this.getValues(newBeneficiaries)});

    // }
  }

  render() {
    const {
      state: { values },
    } = this

    return (
      <div style={{ height: 120, width: '100%' }}>
        <Slider
          step={1}
          domain={domain}
          rootStyle={sliderStyle}
          mode={3}
          onUpdate={this.onUpdate}
          onChange={this.onChange}
          values={values}
        >
          <Rail>
            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => {
              let htotal = [0];
              return <div className="slider-handles">
                {handles.map((handle, handleid) => {
                  htotal.push(handle.value);

                  return <Handle
                    key={handle.id}
                    beneficiary={this.state.beneficiariesAccounts.find((ben, index)=>  index===parseInt(handle.id.replace('$$-', '')) )}
                    spouse = {this.props.spouse}
                    start={htotal[htotal.length - 2]}
                    deleteAction={this.deleteBeneficiary}
                    editAction={this.editBeneficiary}
                    handle={handle}
                    lastChild={handleid === handles.length - 1}
                    domain={domain}
                    getHandleProps={getHandleProps}
                  />
                })}
              </div>
            }}
          </Handles>
          <Tracks left={true} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => {
                  return <Track
                    key={id}
                    beneficiary={this.state.beneficiariesAccounts.find((ben, index)=>  index===parseInt(target.id.replace('$$-', '')) )}
                    spouse = {this.props.spouse}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                })}
              </div>
            )}
          </Tracks>

        </Slider>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  accData: state.accData
})

const mapDispatchToProps = dispatch => ({
  deleteBen: (guid, account) => dispatch({
      type: types.DELETE_BENEFICIARY,
      payload: {guid, account}
  }),
  updatePercentages: (values, account) => dispatch({
    type: types.UPDATE_BENEFICIARIES_PERCENTAGES,
    payload: {values, account}
  })
});

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiariesSlider)