import React, { Fragment, useState, useEffect } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { setAlert } from '../../../actions/alert'
import { camelToSentence } from '../../../utils/misc';

const Insider = ({ parentInsiderList, stateRef, setAlert }) => {
    const [hasError, setHasError] = useState(false);
    
    const [insiderList, setInsiderList] = useState(parentInsiderList);
 
    const validate = () => {
        let fieldsToCheck = ['company', 'market', 'symbol'];
        for (let index in insiderList) {
            for (let i = 0, len = fieldsToCheck.length; i < len; i++) {
                if (!insiderList[index][fieldsToCheck[i]]) {
                    setAlert(camelToSentence(fieldsToCheck[i])+' is required', 'is-danger', 3000);
                    setHasError(fieldsToCheck[i]+"_"+index);
                    return false;
                }
            }
        }
        return true;
    }

    stateRef.current = { insiderList, validate }

    useEffect(() => {
        stateRef.current = { ...stateRef.current, insiderList }
    }, [insiderList, stateRef])

    const deleteItem = (index) => {
        setInsiderList(
            insiderList.filter((_, i) => i !== index)
        )
    }

    const addItem = () => {
        setInsiderList(
            [...insiderList, {company:'', market:'',symbol:'',insider:false, controlperson:false}]
        )
    }

    const updateFields = e => {
        const key = parseInt(e.currentTarget.dataset.key);        
        setInsiderList(
            insiderList.map((entry, index) => index === key ? {...entry, [e.currentTarget.name.replace('insider','').split('_')[0].toLowerCase()]: e.currentTarget.type==='checkbox'?e.currentTarget.checked:e.currentTarget.value} : entry)
        );

        if (e.currentTarget.value && hasError === e.currentTarget.name.replace('insider','').toLowerCase()) setHasError('');
    };

    return (
        <Fragment>
            {insiderList.map(
                (entry, index) => {
                    return <div className="oddBg" key={index}>
                        <div className="columns">
                            <div className="column is-full-mobile is-three-fifths-tablet">
                                <div className="field">
                                    <label htmlFor={`insiderCompany_${index}`} className="label">
                                        Insider Company
                                        {index > 0 && <span title="Delete company" onClick={() => { deleteItem(index) }}><span className="is-link icon is-left"><i className="fas fa-trash"></i></span></span>}
                                    </label>
                                    <input onChange={updateFields} data-key={index} value={entry.company} className={classNames({ "input": true, "is-danger": hasError===`company_${index}` })} name={`insiderCompany_${index}`} id={`insiderCompany_${index}`} required type="text" placeholder="" />
                                </div>
                            </div>
                            <div className="column is-full-mobile is-two-fifths-tablet">
                                <div className="field">
                                    <label htmlFor={`insiderMarket_${index}`} className="label">Market</label>
                                    <input onChange={updateFields} data-key={index} value={entry.market} className={classNames({ "input": true, "is-danger": hasError===`market_${index}` })} name={`insiderMarket_${index}`} id={`insiderMarket_${index}`} required type="text" placeholder="" />
                                </div>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-full-mobile is-three-fifths-tablet">
                                <div className="field">
                                    <label htmlFor={`insiderSymbol_${index}`} className="label">Symbol</label>
                                    <input onChange={updateFields} data-key={index} value={entry.symbol} className={classNames({ "input": true, "is-danger": hasError===`symbol_${index}` })} name={`insiderSymbol_${index}`} id={`insiderSymbol_${index}`} required type="text" placeholder="" />
                                </div>
                            </div>

                            <div className="column is-full-mobile is-two-fifths-tablet">
                                <div className="field">                                    
                                    <label style={{marginRight:'10px'}} className="checkbox"><input data-key={index} name={`insiderInsider_${index}`} onChange={updateFields} checked={entry.insider} type="checkbox" />Insider</label>
                                    <label className="checkbox"><input data-key={index} name={`insiderControlPerson_${index}`} onChange={updateFields} checked={entry.controlperson} type="checkbox" />Control Person</label>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            )}
            <button style={{marginLeft:'5px'}} onClick={addItem} className="button">Add Company</button>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    //accData: state.accData
})

export default connect(mapStateToProps, { setAlert })(Insider)