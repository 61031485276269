import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
//import Linkify from 'react-linkify'
import classNames from 'classnames';
import AccPagination from './AccPagination';
import * as types from '../../../actions/types';
import { store } from './../../../store';

const AccItem = ({ acc, goals }) => {

    const [showNote, toggleNote] = useState(false);

    const onAccClick = (e) => {
        const clickEls = ['goalsnote', 'caption', 'pagination', 'question', 'circle', 'accountContent', 'accountItem', 'pagination-list', 'currentIndex'];
        if (!Array.from(e.currentTarget.classList).includes('is-gray-selected') && !Array.from(e.currentTarget.classList).includes('is-selected')) {
            clickEls.push('select');
            clickEls.push('selectControl');
        }

        if (Array.from(e.target.classList).filter(x => clickEls.includes(x)).length) {
            if (Array.from(e.target.classList).includes('select') || Array.from(e.target.classList).includes('selectControl')) e.preventDefault();

            store.dispatch({
                type: types.TOGGLE_ACCOUNT,
                payload: acc.id
            })
        }

    }

    const onGoalClick = (e) => {
        store.dispatch({
            type: types.SELECT_ACCOUNT_GOAL,
            payload: { id: acc.id, goalID: e.target.value }
        })
    }

    const onSourceClick = (e) => {
        store.dispatch({
            type: types.SELECT_ACCOUNT_SOURCE,
            payload: { id: acc.id, sourceID: e.target.value }
        })
    }

    const onJointChange = (e) => {
        store.dispatch({
            type: types.SELECT_ACCOUNT_JOINT,
            payload: { id: acc.id, isJoint: e.target.checked }
        })
    }

    const onRemoveAccount = () => {
        store.dispatch({
            type: types.REMOVE_ACCOUNT,
            payload: acc.id
        })
    }

    const onAddAccount = () => {
        store.dispatch({
            type: types.ADD_ACCOUNT,
            payload: acc.id
        })
    }

    const currentIndex = acc.selected && acc.accsToAdd.length > 1 ? '#' + (acc.accsToAdd.findIndex(accToAdd => accToAdd.current) + 1) + ": " : '';

    const goalIndex = goals.find(goal => goal.current) ? goals.find(goal => goal.current).id : null;

    const hasSelectedGoal = goalIndex ? acc.accsToAdd.filter(accToAdd => accToAdd.goalID === goalIndex).length > 0 : true;

    return <div className="column is-half-mobile is-half-tablet is-one-third-desktop">
        <div id="1" onClick={onAccClick} className={classNames({ 'accountItem': true, 'hasNote': showNote, 'is-gray-selected': acc.selected && !hasSelectedGoal, 'is-selected': acc.selected && hasSelectedGoal, 'is-multiple': acc.selected && acc.accsToAdd.length > 1 })} /*onClick={accToggle}*/ >

            <div className="accountContent">
                <span title="Details" onClick={() => toggleNote(true)}><i className="fas fa-info-circle"></i></span>
                <span title="Add account" onClick={onAddAccount}><i className="fas fa-plus-circle"></i></span>
                <span title="Remove account" onClick={onRemoveAccount}><i className="fas fa-minus-circle"></i></span>
                <span className="circle"></span>
                <div className="caption">{`${acc.name} (${acc.code})`}</div>

                {/*<div className="question">For which of your goals are you opening this account?</div>*/}
                <div className="selectContainer">
                    <table className="accTable">
                        <tbody>
                            <tr>
                                {currentIndex ? <td className="indexTd"><span className="currentIndex">{currentIndex}</span></td> : null}
                                <td>
                                    <div className={classNames({ "select": true, "is-fullwidth": true, "disabled": !acc.selected })}>
                                        <select className={classNames({ "selectControl": true, "disabled": !acc.selected })} value={acc.selected && acc.accsToAdd.find(x => x.current === true) ? acc.accsToAdd.find(x => x.current === true).goalID : acc.selected ? 1 : 0} onChange={onGoalClick}>
                                            {!acc.selected && <option value={0}>Choose your goal</option>}
                                            {goals.map(goal => <option key={goal.id} value={goal.id}>{goal.name}</option>)}
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            { acc.id === 6 && 
                            <tr>
                                {currentIndex ? <td className="indexTd"></td> : null}
                                <td>
                                    <div style={{marginTop:'5px'}} className={classNames({ "select": true, "is-fullwidth": true, "disabled": !acc.selected })}>
                                        <select className={classNames({ "selectControl": true, "disabled": !acc.selected })} value={acc.selected && acc.accsToAdd.find(x => x.current === true) ? acc.accsToAdd.find(x => x.current === true).sourceID>0?acc.accsToAdd.find(x => x.current === true).sourceID:0 : 0} onChange={onSourceClick}>
                                            <option value={0}>Source of funds</option>
                                            <option value="17">Investment earnings</option>
                                            <option value="18">Employment earnings</option>
                                            <option value="19">Sale of property</option>
                                            <option value="20">Rental income</option>
                                            <option value="21">Savings</option>
                                            <option value="22">Retirement income</option>
                                            <option value="23">Gift/donation</option>
                                            <option value="24">Inheritance</option>
                                            <option value="25">Insurance settlement</option>
                                            
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="questioncheckbox">
                    {acc.id >= 5 && <Fragment> <label><input onChange={onJointChange} checked={acc.selected && acc.accsToAdd.find(x => x.current === true) ? acc.accsToAdd.find(x => x.current === true).isJoint : false} type="checkbox" /><i className="fas fa-user-friends"></i>Joint account?</label> </Fragment>}
                </div>

            </div>

            <AccPagination acc={acc} />
            <div className="accountNote">
                <span title="Close" onClick={() => toggleNote(false)}><i className="fas fa-times-circle"></i></span>
                {/*<Linkify properties={{target: '_blank', style: {color: 'red', fontWeight: 'bold'}}}>{acc.description}</Linkify>*/}
                <div dangerouslySetInnerHTML={{ __html: acc.description }} ></div>
            </div>
        </div>
    </div>;
}

AccItem.propTypes = {
    acc: PropTypes.object.isRequired,
    goals: PropTypes.array.isRequired
}

export default AccItem