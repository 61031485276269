import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { setAlert } from '../../../actions/alert'
import { camelToSentence } from '../../../utils/misc';
import NumberFormat from 'react-number-format'

const ContributionForm = ({ parentContribution, parentAccount, stateRef,accData, setAlert }) => {
    const [hasError, setHasError] = useState(false);
    const multiple_accounts = accData.accountTypes.filter(x=>x.selected).length>1;
    const [contribution, setContribution] = useState(parentContribution !== null ? parentContribution : {
        account: parentAccount?parentAccount:(multiple_accounts?'':accData.accountTypes.find(x=>x.selected).id+"_0"),
        amount: '',
        frequency:'One-time',
        effectiveDate:''
    });

    const updateFields = e => {        
        setContribution({
            ...contribution,
            [e.target.name]: e.target.value
        });
        if (e.target.value.trim() && hasError === e.target.name) setHasError('');
    };

    const validate = () => {
        const fieldsToCheck = ['amount', 'frequency','effectiveDate'];
        if (multiple_accounts) fieldsToCheck.push('account');
        
        for (let i = 0, len = fieldsToCheck.length; i < len; i++) {
            if (!contribution[fieldsToCheck[i]].trim() || (fieldsToCheck[i]==='amount' && parseFloat(contribution[fieldsToCheck[i]])===0)) {
                setAlert(camelToSentence(fieldsToCheck[i]) + ' is required', 'is-danger', 3000);
                setHasError(fieldsToCheck[i]);
                return false;
            } 
        }
        return true;
    }

    stateRef.current = { contribution, validate }
    
    return (
        <Fragment>
            <div className="columns field">
                
                {multiple_accounts &&
                <div className="column is-7">
                    <label htmlFor={`account`} className="label">
                        Which account you wish to conribute to?
                    </label>
                    <div className="control">                                    
                        <div className={classNames({ "select is-fullwidth": true, 'is-danger':hasError===`account` })} >
                            <select onChange={updateFields} value={contribution.account} required name="account" id="3">
                                <option>Select account</option>
                                {accData.accountTypes.map(val => {
                                    if (val.selected) {
                                        return val.accsToAdd.map((acc, index) => {
                                            return <option key={val.id + '_' + index} value={val.id + '_' + index}>{val.name + (val.accsToAdd.length > 1 ? " #" + (index + 1) : '')}</option>
                                        })
                                    } else return null;
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                }

                <div className={`column is-${multiple_accounts?'5':'12'}`}>
                    <label htmlFor="amount" className="label">Amount</label>
                    <p className="control has-icons-left">
                        <NumberFormat displayType={'input'} thousandSeparator={true} onChange={e=>updateFields({target: {name: `amount`, value: e.target.value }})} value={contribution.amount} className={classNames({ "input": true, "is-danger": hasError===`amount` })} name={`amount`} id={`amount`} required placeholder="" />
                        <span className="icon is-left"><i className="fas fa-dollar-sign"></i></span>
                    </p>
                </div> 
            </div>
            <div className="columns field">
                <div className="column is-7">
                    <label htmlFor={`frequency`} className="label">
                        Frequency
                    </label>
                    <div className={classNames({"control":true, 'dangerSelect':hasError===`frequency`})}>
                        <div className={classNames({ "is-fullwidth": true, "is-danger": false })} >                                        
                            <div className={classNames({ "select is-fullwidth": true })} >
                                <select onChange={updateFields} value={contribution.frequency} required name="frequency" id="frequency">
                                    <option value="One-Time">One-time</option>
                                    <option value="Weekly">Weekly</option>
                                    <option value="Bi-Weekly">Bi-weekly</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Quarterly">Quarterly</option>
                                    <option value="Annually">Annually</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="column is-5">
                    <label htmlFor="effectiveDate" className="label">Effective (date of first contribution):</label>
                    <p className="control">
                        <input type="date" onChange={updateFields} value={contribution.effectiveDate} className={classNames({ "input": true, "is-danger": hasError===`effectiveDate` })} name={`effectiveDate`} id={`effectiveDate`} required placeholder="" />
                    </p>
                </div> 
            </div>
        </Fragment>
    )
}

ContributionForm.propTypes = {
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    accData: state.accData
})

export default connect(mapStateToProps, { setAlert })(ContributionForm)