import React, { Fragment} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setAlert } from '../../../actions/alert'
import NumberFormat from 'react-number-format'

const Employment = ({prefix, title, annualIncome, status, employersName, occupation, typeOfBusiness, updateFields  }) => {
   
    return (
        <Fragment>
        <div className="columns is-mobile is-multiline is-fullwidth topcolumns">
            <div className="column is-half-mobile is-half-tablet is-one-third-desktop">
                <div className="field" style={{textAlign:'center'}}>
                    <label htmlFor="annualIncome" className="label">{prefix==='' && !title && 'What is Your '}Annual Income{prefix==='' && !title && '?'}</label>                        
                    <div className="incomeWrapper">
                        <div className="incomeContainer">
                            <div style={{textAlign:'center'}}>                 
                                <p className="control has-icons-left">               
                                    <NumberFormat displayType={'input'} thousandSeparator={true} onChange={updateFields} value={annualIncome} className="input" name={`${prefix}annualIncome`} id="annualIncome" required placeholder="56,000" />

                                    <span className="icon is-left"><i className="fas fa-dollar-sign"></i></span>
                                </p>
                                <div style={{zIndex:1,color:'#616161',position:'relative', marginTop:'2rem'}}>CAD</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column is-half-mobile is-half-tablet is-two-thirds-desktop">
                <div className="columns field">
                    <div className="column">
                        <label htmlFor="status" className="label">Status</label>
                        
                        <div className="select is-fullwidth"><select onChange={updateFields} value={status} required name={`${prefix}status`}><option value="Employed">Employed</option> <option value="Unemployed">Unemployed</option> <option value="Retired">Retired</option> <option value="Self employed">Self employed</option></select></div>
                        
                    </div>
                    {status==='Employed' &&
                    <div className="column">
                        <label htmlFor="employersName" className="label">Employer's name</label>                            
                            <input onChange={updateFields} value={employersName} className="input" name={`${prefix}employersName`} id="employersName" type="text" placeholder="" />                            
                    </div>
                    }
                </div>
                {status==='Employed' &&
                <div className="columns field">
                    <div className="column">
                        <label htmlFor="status" className="label">Occupation</label>
                        <input onChange={updateFields} value={occupation} className="input" name={`${prefix}occupation`}  id="occupation" type="text" placeholder="" />
                    </div>
                    <div className="column">
                        <label htmlFor="typeOfBusiness" className="label">Type of Business</label>                            
                        <input onChange={updateFields} value={typeOfBusiness} className="input" name={`${prefix}typeOfBusiness`}  id="typeOfBusiness" type="text" placeholder="" />
                    </div>
                </div>
                }
            </div>
        </div>
    </Fragment>
    )
}

Employment.propTypes = {
    prefix: PropTypes.string.isRequired,status: PropTypes.string.isRequired, employersName: PropTypes.string.isRequired, occupation:PropTypes.string.isRequired, typeOfBusiness:PropTypes.string.isRequired, updateFields:PropTypes.func.isRequired
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, { setAlert })(Employment)