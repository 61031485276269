import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Ticks extends Component {
  render() {
    const {
      children,
      values,
      scale,
      count,
      getEventData,
      activeHandleID,
    } = this.props
    const ticks = (values ? values : scale.getTicks(count)).map(value => {
      return {
        id: `$$-${value}`,
        value,
        percent: scale.getValue(value),
      }
    })

    const renderedChildren = children({ getEventData, activeHandleID, ticks })
    return renderedChildren && React.Children.only(renderedChildren)
  }
}

Ticks.propTypes = {
  /** @ignore */
  scale: PropTypes.object,
  /**
   * Approximate number of ticks you want to render.
   * If you supply your own ticks using the values prop this prop has no effect.
   */
  count: PropTypes.number,
  /**
   * The values prop should be an array of unique numbers.
   * Use this prop if you want to specify your own tick values instead of ticks generated by the slider.
   * The numbers should be valid numbers in the domain and correspond to the step value.
   * Invalid values will be coerced to the closet matching value in the domain.
   */
  values: PropTypes.array,
  /** @ignore */
  getEventData: PropTypes.func,
  /** @ignore */
  activeHandleID: PropTypes.string,
  /** @ignore */
  emitMouse: PropTypes.func,
  /** @ignore */
  emitTouch: PropTypes.func,
  /**
   * A function to render the ticks.
   * The function receives an object with an array of ticks. Note: `getEventData` can be called with an event and get the value and percent at that location (used for tooltips etc). `activeHandleID` will be a string or null.  Function signature:
   * `({ getEventData, activeHandleID, ticks  }): element`
   */
  children: PropTypes.func.isRequired,
}

Ticks.defaultProps = {
  count: 10,
}

export default Ticks
