import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Main from './components/Main';
import Alert from './components/layout/alert';
import ModalComponent from './utils/modalComponent';
import './App.css';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react'

const App = () => {
   return (
      <Provider store={store}>
         <PersistGate loading={null} persistor={persistor}>
            <Alert />
            <ModalComponent />
            <Header />
            <div id="cnt" className="container content-container">
               <div className="logo">
                  <i className="fas fa-wrench"></i> Account opening prototype
               </div>
               <Main />
            </div>
            <Footer />
         </PersistGate>
      </Provider>
   )
}



export default App;
