import React from "react";
import * as types from "./../actions/types";
import { store } from "./../store";
import { withRouter } from "react-router-dom";

const Footer = ({ history }) => (
  <footer className="footer">
    <div className="container">
      <div className="columns is-mobile">
        <div className="column">logo here</div>
        <div className="colum has-text-right">
          <div style={{ textTransform: "uppercase", fontSize: "1.4rem" }}>Do you have any questions?</div>
          <button data-target="#myModal" onClick={() => store.dispatch({ type: types.SET_MODAL, payload: { title: "Contacts title placeholder", saveButton: false, content: "Contacts placeholder" } })} href="#!" style={{ marginTop: "10px", marginBottom: "15px" }} className="button iaButton trButton bg-lightblue modal-button">
            <span>?</span>
            <div className="triangle"></div> Contact us
          </button>
        </div>
      </div>

      <div className="columns is-mobile">
        <div className="column">
          <a
            href="#!"
            onClick={() => {
              history.push("/");
              store.dispatch({ type: types.CLEAR_STATE });
            }}
            className="is-link"
          >
            Clear state
          </a>
          <div className="creds">
            {new Date().getFullYear()}. UX/UI Design:{" "}
            <a href="https://www.linkedin.com/in/tanyapelkonen/" rel="noopener noreferrer" target="_blank">
              Tanya Pelkonen
            </a>
            . Development:{" "}
            <a rel="noopener noreferrer" href="https://www.linkedin.com/in/elias-p-74a8aa172/" target="_blank">
              Elias Pelkonen.
            </a>
          </div>
        </div>
        <div className="colum has-text-right">
          <div style={{ fontSize: "1.6rem", textTransform: "uppercase", fontWeight: "bold" }}>Slogan here.</div>
        </div>
      </div>
    </div>
  </footer>
);

export default withRouter(Footer);
