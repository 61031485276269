import * as types from '../actions/types';

const initialState = {
    loading: true,
    accountTypes: [], //id: 1, name: 'Tax-Free Saving Account', code: 'TFSA', description: 'A Tax-Free Savings Account is a flexible, registered, general-purpose savings vehicle that allows Canadians to earn tax-free investment income to more easily meet lifetime needs. Investment income inside a TFSA is tax-free, as are withdrawals from a TFSA. Please see <a href="https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/tax-free-savings-account.html" target="_blank">www.canada.ca/en/revenue-agency/services/tax/individuals/topics/tax-free-savings-account.html</a> for more details.', selected: false, hidden: false, recommendedGoals: [1, 2, 3, 4], accsToAdd: []
    goals: [
        { id: 1, name: 'Retirement', current: false },
        { id: 2, name: 'Education', current: false },
        { id: 3, name: 'Major purchase', current: false }
    ]
}

export default function (state = initialState, action) {
    const selectedGoal = state.goals.find(goal => goal.current) ? state.goals.find(goal => goal.current).id : null;
    const selectedAcc = state.accountTypes.find(acc => acc.id === action.payload);
    const hasSelectedGoal = selectedGoal && selectedAcc ? selectedAcc.accsToAdd.filter(accToAdd => accToAdd.goalID === selectedGoal).length > 0 : false;
    let goalToAdd = selectedGoal ? selectedGoal : null;
    if (goalToAdd === null && selectedAcc) {
        //calculating next goal for this account
        let unfulfilledGoals = [];
        for (let index in state.goals) {
            if (!state.accountTypes.filter(account => { return account.selected && account.accsToAdd.filter(acctoAdd => { return acctoAdd.goalID === state.goals[index].id }).length }).length) unfulfilledGoals.push(state.goals[index].id);
        }

        let nextPossibleGoals = selectedAcc.recommendedGoals.filter(x => unfulfilledGoals.includes(x));
        goalToAdd = nextPossibleGoals.length ? nextPossibleGoals[0] : selectedAcc.recommendedGoals[0];
    }

    switch (action.type) {
        case types.GET_ACCOUNTS: {
            return {
                ...state,
                accountTypes: action.payload.map(x => ({ ...x, accsToAdd: x.accsToAdd ? x.accsToAdd : [], selected: false, hidden: false })),
                loading: false
            }
        }
        case types.TOGGLE_ACCOUNT:
            if (selectedAcc.selected) {
                if (!selectedGoal) { selectedAcc.selected = false; if (selectedAcc.accsToAdd.length === 1) selectedAcc.accsToAdd = []; } else {
                    if (hasSelectedGoal) {
                        selectedAcc.accsToAdd = selectedAcc.accsToAdd.filter(accToAdd => accToAdd.goalID !== selectedGoal);

                        if (selectedAcc.accsToAdd.length === 0) selectedAcc.selected = false; else {
                            selectedAcc.accsToAdd = selectedAcc.accsToAdd.map((accToAdd, index) => ({ ...accToAdd, current: !index }));
                        }
                    } else {
                        selectedAcc.accsToAdd = selectedAcc.accsToAdd.map(accToAdd => ({ ...accToAdd, current: false }));
                        selectedAcc.accsToAdd.push({ goalID: goalToAdd, isJoint: false, current: true });
                    }
                }
            } else {
                if ((selectedAcc.accsToAdd.filter(accToAdd => accToAdd.goalID === goalToAdd).length === 0) || (selectedAcc.selected && !hasSelectedGoal)) {
                    selectedAcc.accsToAdd = selectedAcc.accsToAdd.map(accToAdd => ({ ...accToAdd, current: false }));
                    selectedAcc.accsToAdd.push({ goalID: goalToAdd, isJoint: false, current: true });
                }
                selectedAcc.selected = true;
            }
            return {
                ...state,
                accountTypes: state.accountTypes.map(acc => acc.id === action.payload ? selectedAcc : acc)
            }
        case types.ADD_ACCOUNT:
            return {
                ...state,
                accountTypes: state.accountTypes.map(acc => {
                    if (acc.id === action.payload) {
                        if (acc.selected) {
                            return { ...acc, accsToAdd: [...acc.accsToAdd.map(accToAdd => ({ ...accToAdd, current: false })), { goalID: goalToAdd, isJoint: false, current: true }] }
                        } else {
                            if (acc.accsToAdd.length === 0) {
                                acc.accsToAdd.push({ goalID: goalToAdd, isJoint: false, current: true });
                            }
                            return { ...acc, selected: true };
                        }
                    }
                    else return acc;
                })
            }
        case types.REMOVE_ACCOUNT:
            return {
                ...state,
                accountTypes: state.accountTypes.map(acc => {
                    if (acc.id === action.payload) {
                        let currentIndex = acc.accsToAdd.findIndex(accToAdd => accToAdd.current);
                        if (currentIndex === 0) {
                            //if (acc.accsToAdd.length === 1) return {...acc, selected: false};
                            currentIndex = 1;
                        }

                        return acc.selected ? { ...acc, selected: acc.accsToAdd.length > 1, accsToAdd: [...acc.accsToAdd.filter(accToAdd => !accToAdd.current).map((accToAdd, index) => ((index === currentIndex - 1) ? { ...accToAdd, current: true } : accToAdd))] }
                            : acc;
                    }
                    else return acc;
                })
            }
        case types.SELECT_ACCOUNT:
            return {
                ...state,
                accountTypes: state.accountTypes.map(acc => {
                    if (acc.id === action.payload.id) {
                        return { ...acc, accsToAdd: [...acc.accsToAdd.map((accToAdd, index) => ({ ...accToAdd, current: index === action.payload.number }))] };
                    }
                    else return acc;
                })
            }
        case types.SELECT_ACCOUNT_GOAL:
            return {
                ...state,
                accountTypes: state.accountTypes.map(acc => {
                    if (acc.id === action.payload.id) {
                        return { ...acc, accsToAdd: [...acc.accsToAdd.map((accToAdd) => ({ ...accToAdd, goalID: (accToAdd.current ? parseInt(action.payload.goalID) : accToAdd.goalID) }))] };
                    }
                    else return acc;
                })
            }
        case types.SELECT_ACCOUNT_SOURCE:
            return {
                ...state,
                accountTypes: state.accountTypes.map(acc => {
                    if (acc.id === action.payload.id) {
                        return { ...acc, accsToAdd: [...acc.accsToAdd.map((accToAdd) => ({ ...accToAdd, sourceID: (accToAdd.current ? parseInt(action.payload.sourceID) : accToAdd.sourceID) }))] };
                    }
                    else return acc;
                })
            }

        case types.SELECT_ACCOUNT_JOINT:
            return {
                ...state,
                accountTypes: state.accountTypes.map(acc => {
                    if (acc.id === action.payload.id) {
                        return { ...acc, accsToAdd: [...acc.accsToAdd.map((accToAdd) => ({ ...accToAdd, isJoint: (accToAdd.current ? action.payload.isJoint : accToAdd.isJoint) }))] };
                    }
                    else return acc;
                })
            }

        case types.SELECT_GOAL:
            return {
                ...state,
                accountTypes: state.accountTypes.map(acc => {
                    const accWithGoal = acc.accsToAdd.findIndex(accToAdd => accToAdd.goalID === action.payload);
                    if (accWithGoal >= 0) {
                        acc.accsToAdd = acc.accsToAdd.map((accToAdd, index) => ({ ...accToAdd, current: index === accWithGoal }));
                    }

                    return { ...acc, hidden: action.payload && !acc.recommendedGoals.includes(action.payload) }
                }),
                goals: state.goals.map(goal => ({ ...goal, current: goal.id === action.payload }))
            }
        default:
            return state;
    }
}