import axios from 'axios';

export default axios.create({
    baseURL: '/WebAPI.aspx/',
    headers: {
        "Accept" : "application/json",
        "Content-Type" : "application/json",
        "X-AuthToken" : "8DVOHndi5sheGGPef4KRECExGyEZ+Yceu6RHnGR7SEc=",
        "charset" : "UTF-8"
      },
    auth: {username: 'Web_Access4API',password: 'viC4S-0m11O#'}
});