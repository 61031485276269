import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { setAlert } from '../../../actions/alert'
import { countryList, camelToSentence } from '../../../utils/misc';
import InputMask from 'react-input-mask'
import { getAccList } from '../../../utils/misc';
import MultiSelect from '../../../utils/multiSelect'

const BeneficiaryForm = ({ parentBeneficiary, parentAccount, stateRef, accData, setAlert }) => {
    const [hasError, setHasError] = useState(false);

    const [beneficiary, setBeneficiary] = useState(parentBeneficiary !== null ? parentBeneficiary : {
        selectedAccounts: parentAccount ? [parentAccount] : [],
        type: '',
        relationship: '',
        gender: '',
        firstName: '',
        middleName: '',
        lastName: '',
        dob: '',
        sin: '',
        mailingDifferent: '',
        mailingAddress: { unit: '', street: '', city: '', province: '', postalCode: '', country: '', }
    });

    const updateFields = e => {
        if (e.target.name.includes('.')) {
            const tNames = e.target.name.split(".");
            setBeneficiary({
                ...beneficiary,
                [tNames[0]]: { ...beneficiary[tNames[0]], [tNames[1]]: e.target.value }
            });

        } else
            setBeneficiary({
                ...beneficiary,
                [e.target.name]: e.target.value
            });
        if (e.target.value.trim() && hasError === e.target.name) setHasError('');
    };

    const validate = () => {
        const fieldsToCheck = ['firstName'];// ['firstName','lastName','relationship','dob','sin']; //'street', 'city', 'postalCode', 'country'
        if (getAccList(accData.accountTypes).length > 1) fieldsToCheck.push('selectedAccounts');
        for (let i = 0, len = fieldsToCheck.length; i < len; i++) {
            if ((fieldsToCheck[i] === 'selectedAccounts' && !beneficiary.selectedAccounts.length) || !beneficiary[fieldsToCheck[i]]) {
                setAlert(camelToSentence(fieldsToCheck[i]) + ' is required', 'is-danger', 3000);
                setHasError(fieldsToCheck[i]);
                return false;
            }
        }
        if (beneficiary['mailingDifferent'] === 'yes') {
            const fieldsToCheck = ['street', 'city', 'postalCode', 'country'];
            for (let i = 0, len = fieldsToCheck.length; i < len; i++) {
                if (!beneficiary.mailingAddress[fieldsToCheck[i]].trim()) {
                    setAlert(camelToSentence(fieldsToCheck[i]) + ' is required', 'is-danger', 3000);
                    setHasError(fieldsToCheck[i]);
                    return false;
                }
            }
        }


        return true;
    }

    stateRef.current = { beneficiary, validate }

    const accList = getAccList(accData.accountTypes.filter(x => x.id !== 6));

    const updateAccountsList = (selectedAccounts) => {
        setBeneficiary({ ...beneficiary, selectedAccounts });
    }

    return (
        <Fragment>
            {accList.length > 1 &&
                <div className="columns field">
                    <div className="column">
                        <label htmlFor={`selectedAccounts`} className="label">
                            Designate beneficiary on the following accounts:
                    </label>
                        <div className={classNames({ "control": true, 'dangerSelect': hasError === `selectedAccounts` })}>
                            <div className={classNames({ "is-fullwidth": true, "is-danger": false })} >
                                <MultiSelect name={`selectedAccounts`} id={`selectedAccounts`} options={accList} disableSearch={true}
                                    selected={beneficiary.selectedAccounts.filter(x => accData.accountTypes.filter(acc => acc.selected && acc.id === parseInt(x.split('_')[0].toLowerCase())).length)} overrideStrings={{ selectSomeItems: "Select account(s)", allItemsAreSelected: "All Accounts", selectAll: "Select All" }} onSelectedChanged={selectedAccounts => updateAccountsList(selectedAccounts)} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="field">
                <div className="columns">
                    <div className="column">    <input onChange={updateFields} value={beneficiary.firstName} className={classNames({ "input": true, "is-danger": hasError === 'firstName' })} name="firstName" type="text" placeholder="First Name" />  </div>
                    <div className="column">    <input onChange={updateFields} value={beneficiary.middleName} className="input" name="middleName" type="text" placeholder="Middle Name" />  </div>
                    <div className="column">    <input onChange={updateFields} value={beneficiary.lastName} className={classNames({ "input": true, "is-danger": hasError === 'lastName' })} name="lastName" type="text" required placeholder="Last Name" />  </div>
                </div>
            </div>

            <div className="columns field">
                <div className="column">
                    <label htmlFor="relationship" className="label">Relationship</label>
                    <p className="control">
                        <input onChange={updateFields} value={beneficiary.relationship} className={classNames({ "input": true, "is-danger": hasError === 'relationship' })} name="relationship" id="relationship" type="text" placeholder="" />
                    </p>
                </div>
                <div className="column is-narrow">
                    <label htmlFor="gender" className="label">Gender</label>
                    <p className="control">
                        <input onChange={updateFields} value={beneficiary.gender} className={classNames({ "input": true, "is-danger": hasError === 'gender' })} name="gender" id="gender" type="text" placeholder="" />
                    </p>
                </div>
            </div>

            <div className="field">
                <div className="columns">
                    <div className="column">
                        <div className="field">
                            <label htmlFor="dob" className="label">Date of birth</label>
                            <p className="control has-icons-left">
                                <input onChange={updateFields} value={beneficiary.dob} className={classNames({ "input": true, "is-danger": hasError === 'dob' })} name="dob" id="dob" required type="date" placeholder="mm/dd/yyyy" />
                                <span className="icon is-left"><i className="fas fa-calendar-alt"></i></span>
                            </p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="field">
                            <label htmlFor="sin" className="label">Social Insurance Number</label>
                            <p className="control has-icons-left">
                                <InputMask onChange={updateFields} value={beneficiary.sin} className={classNames({ "input": true, "is-danger": hasError === 'sin' })} name="sin" id="sin" type="text" required placeholder="111-111-111" mask="999-999-999" maskChar="" />
                                <span className="icon is-left"><i className="fas fa-id-card"></i></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field">
                <div className="columns reversed">
                    <div className="column is-narrow">
                        <div className={classNames({ "control has-actions": true })}>
                            <div className="select is-fullwidth">
                                <select onChange={updateFields} value={beneficiary.mailingDifferent} name="mailingDifferent" id="mailingDifferent"><option value={'no'}>No</option><option value={'yes'}>Yes</option></select>
                            </div>
                        </div>

                    </div>
                    <div className="column labelonly">
                        <label className="label" htmlFor="mailingDifferent"><span>Is this person's mailing address different from the applicants?</span></label>
                    </div>
                </div>
            </div>

            {beneficiary.mailingDifferent === 'yes' &&
                <Fragment>
                    <div className="columns field">
                        <div className="column is-narrow">
                            <label htmlFor="unit" className="label">Unit</label>
                            <p className="control">
                                <input onChange={updateFields} value={beneficiary.mailingAddress.unit} className="input" name="mailingAddress.unit" id="unit" type="text" placeholder="" />
                            </p>
                        </div>
                        <div className="column">
                            <label htmlFor="street" className="label">Street</label>
                            <p className="control">
                                <input onChange={updateFields} value={beneficiary.mailingAddress.street} className={classNames({ "input": true, "is-danger": hasError === 'street' })} name="mailingAddress.street" id="street" type="text" placeholder="" />
                            </p>
                        </div>
                    </div>


                    <div className="columns field">
                        <div className="column is-4">
                            <label htmlFor="city" className="label">City</label>
                            <p className="control">
                                <input onChange={updateFields} value={beneficiary.mailingAddress.city} className={classNames({ "input": true, "is-danger": hasError === 'city' })} name="mailingAddress.city" id="city" type="text" placeholder="" />
                            </p>
                        </div>

                        <div className="column is-4">
                            <label htmlFor="province" className="label">Province</label>
                            <p className="control">
                                <input onChange={updateFields} value={beneficiary.mailingAddress.province} className="input" name="mailingAddress.province" id="province" type="text" placeholder="" />
                            </p>
                        </div>

                        <div className="column is-4">
                            <label htmlFor="postalCode" className="label">Postal Code</label>
                            <p className="control">
                                <input onChange={updateFields} value={beneficiary.mailingAddress.postalCode} className={classNames({ "input": true, "is-danger": hasError === 'postalCode' })} name="mailingAddress.postalCode" id="postalCode" type="text" placeholder="" />
                            </p>
                        </div>
                    </div>


                    <div className="columns field">
                        <div className="column is-full">
                            <label htmlFor="country" className="label">Country</label>
                            <div className="control has-icons-left">
                                <div className={classNames({ "select has-icons-left is-fullwidth": true, "is-danger": hasError === 'country' })} >
                                    <select onChange={updateFields} value={beneficiary.mailingAddress.country} required name="mailingAddress.country" id="country">
                                        <option value="">Country</option>
                                        {countryList.map((val, index) => {
                                            return <option key={index} value={val}>{val}</option>
                                        })}
                                    </select>
                                    <span className="icon is-left"><i className="fas fa-globe"></i></span>
                                </div>
                            </div>
                        </div>
                    </div> </Fragment>}
        </Fragment>
    )
}

BeneficiaryForm.propTypes = {
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    accData: state.accData
})

export default connect(mapStateToProps, { setAlert })(BeneficiaryForm)