import React, { Fragment, useState, useEffect } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { setAlert } from '../../../actions/alert'
import { camelToSentence } from '../../../utils/misc';

const ThirdParty = ({ parentThirdPartyList, stateRef, setAlert }) => {
    const [hasError, setHasError] = useState(false);
    
    const [thirdPartyList, setThirdPartyList] = useState(parentThirdPartyList);
 
    const validate = () => {
        let fieldsToCheck = ['name', 'occupation', 'relationship', 'dob', 'address'];
        for (let index in thirdPartyList) {
            for (let i = 0, len = fieldsToCheck.length; i < len; i++) {
                if (!thirdPartyList[index][fieldsToCheck[i]]) {
                    setAlert(camelToSentence(fieldsToCheck[i])+' is required', 'is-danger', 3000);
                    setHasError(fieldsToCheck[i]+"_"+index);
                    return false;
                }
            }
        }
        return true;
    }

    stateRef.current = { thirdPartyList, validate }

    useEffect(() => {
        stateRef.current = { ...stateRef.current, thirdPartyList }
    }, [thirdPartyList, stateRef])

    const deleteItem = (index) => {
        setThirdPartyList(
            thirdPartyList.filter((_, i) => i !== index)
        )
    }

    const addItem = () => {
        setThirdPartyList(
            [...thirdPartyList, {name:'',occupation:'',relationship:'',dob:'',address:''}]
        )
    }

    const updateFields = e => {
        const key = parseInt(e.currentTarget.dataset.key);        
        setThirdPartyList(
            thirdPartyList.map((entry, index) => index === key ? {...entry, [e.currentTarget.name.replace('DateOfBirth','dob').replace('thirdParty','').split('_')[0].toLowerCase()]: e.currentTarget.value } : entry)
        );

        if (e.currentTarget.value && hasError === e.currentTarget.name.replace('thirdParty','').toLowerCase()) setHasError('');
    };

    return (
        <Fragment>
            {thirdPartyList.map(
                (entry, index) => {
                    return <div className="oddBg" key={index}>
                         <div className="columns">
                            <div className="column is-full-mobile is-three-fifths-tablet">
                                <div className="field">
                                    <label htmlFor={`thirdPartyCompany_${index}`} className="label">
                                        Third Party Name
                                        {index > 0 && <span title="Delete name" onClick={() => { deleteItem(index) }}><span className="is-link icon is-left"><i className="fas fa-trash"></i></span></span>}
                                    </label>
                                    <input onChange={updateFields} data-key={index} value={entry.name} className={classNames({ "input": true, "is-danger": hasError===`name_${index}` })} name={`thirdPartyName_${index}`} id={`thirdPartyName_${index}`} required type="text" placeholder="" />
                                </div>
                            </div>
                            <div className="column is-full-mobile is-two-fifths-tablet">
                                <div className="field">
                                    <label htmlFor={`thirdPartyOccupation_${index}`} className="label">Principal Business or Occupation</label>
                                    <input onChange={updateFields} data-key={index} value={entry.occupation} className={classNames({ "input": true, "is-danger": hasError===`occupation_${index}` })} name={`thirdPartyOccupation_${index}`} id={`thirdPartyOccupation_${index}`} required type="text" placeholder="" />
                                </div>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-full-mobile is-three-fifths-tablet">
                                <div className="field">
                                    <label htmlFor={`thirdPartyRelationship_${index}`} className="label">Relationship to Account Holder</label>
                                    <input onChange={updateFields} data-key={index} value={entry.relationship} className={classNames({ "input": true, "is-danger": hasError===`relationship_${index}` })} name={`thirdPartyRelationship_${index}`} id={`thirdPartyRelationship_${index}`} required type="text" placeholder="" />
                                </div>
                            </div>

                            <div className="column is-full-mobile is-two-fifths-tablet">
                                <div className="field">                                    
                                    <label htmlFor={`thirdPartyDateOfBirth_${index}`} className="label">Date of birth of Third Party</label>
                                    <p className="control has-icons-left">
                                        <input onChange={updateFields} data-key={index} value={entry.dob} className={classNames({ "input": true, "is-danger": hasError===`dateofbirth_${index}` })} name={`thirdPartyDateOfBirth_${index}`} id={`thirdPartyDateOfBirth_${index}`} required type="date" placeholder="mm/dd/yyyy" />
                                        <span className="icon is-left"><i className="fas fa-calendar-alt"></i></span>
                                    </p>  
                                </div>
                            </div>
                        </div>           

                        <div className="columns">
                            <div className="column is-full-mobile">
                                <div className="field">
                                    <label htmlFor={`thirdPartyAddress_${index}`} className="label">Third Party Address</label>
                                    <input onChange={updateFields} data-key={index} value={entry.address} className={classNames({ "input": true, "is-danger": hasError===`address_${index}` })} name={`thirdPartyAddress_${index}`} id={`thirdPartyAddress_${index}`} required type="text" placeholder="" />
                                </div>
                            </div>
                        </div> 

                    </div>
                }
            )}
            <button style={{marginLeft:'5px'}} onClick={addItem} className="button">Add Third Party</button>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    //accData: state.accData
})

export default connect(mapStateToProps, { setAlert })(ThirdParty)