import * as types from '../actions/types';
import uuidv4 from 'uuid/v4';
//import { array_move } from '../utils/misc';

const initialState = {
    beneficiariesList: [
    ],
    percentages: {}
}

export default function (state = initialState, action) {    
    let percentages = [];
    
    switch (action.type) {        
        case types.UPDATE_BENEFICIARY:
            let newBeneficiary = action.payload;
            
            const guid = newBeneficiary.guid?newBeneficiary.guid:uuidv4();
            percentages = {...state.percentages};

            newBeneficiary.selectedAccounts.forEach(acc => {
                if (!Array.isArray(percentages[acc])) {
                    percentages[acc] = [];
                    percentages[acc].push({guid, percentage:100});
                } else if (!percentages[acc].find(x=>x.guid===guid)) {
                    let totalP = 0;
                    percentages[acc] = percentages[acc].map(p=>{
                        const benp = (p.percentage/(percentages[acc].length+1))*percentages[acc].length; 
                        totalP+=benp;
                        return {
                        ...p, percentage: benp
                        }
                    });
                    percentages[acc].push({guid, percentage:(100-totalP)});
                }
            });
            if (!newBeneficiary.guid) {
                //new beneficiary
                
                return {
                    ...state,
                    beneficiariesList: [...state.beneficiariesList, {...newBeneficiary, color: ['#2E63CC', '#009EEE', '#00D0E5', '#5AECC0', '#CDE43F'][state.beneficiariesList.length % 5] , guid}],
                    percentages
                }
            } else {
                //existing beneficiary
                // {...newBeneficiary, selectedAccounts: [...newBeneficiary.selectedAccounts, action.payload.account ]  }
                return {
                    ...state,
                    beneficiariesList: state.beneficiariesList.map(x=>x.guid===guid?   newBeneficiary      :x),
                    percentages
                }
            }
        case types.DELETE_BENEFICIARY:
            //return state;
            percentages = {...state.percentages};
            const beneficiary = state.beneficiariesList.find(x=>x.guid===action.payload.guid);
            const beneficiary_percentage = percentages[action.payload.account].find(x=>x.guid===action.payload.guid).percentage;

            percentages[action.payload.account] = percentages[action.payload.account].filter(x=>x.guid!==action.payload.guid).map((p)=>{
                return {...p, percentage: p.percentage + ((p.percentage/(100-beneficiary_percentage))*beneficiary_percentage) }
            })

            if (beneficiary.selectedAccounts.length===1 && beneficiary.selectedAccounts.includes(action.payload.account)) {
                //delete beneficiary completely
                return {
                    ...state,
                    beneficiariesList: state.beneficiariesList.filter(x=>x.guid!==action.payload.guid),
                    percentages
                }
            } else {
                //only uncheck account for the beneficiary
                return {
                    ...state,
                    beneficiariesList: state.beneficiariesList.map(x=>x.guid!==action.payload.guid?x:{...x, selectedAccounts: x.selectedAccounts.filter(x=>x!==action.payload.account)  }),
                    percentages
                }
            }

        case types.UPDATE_BENEFICIARIES_PERCENTAGES:
            return {
                ...state,
                percentages: {...state.percentages, 
                    [action.payload.account]:state.percentages[action.payload.account].map((p,index)=>({...p, 
                        percentage: action.payload.values.find(x=>x.guid===p.guid).percentage
                    }
                ))}
            }        
        default:
            return state;
    }
}
