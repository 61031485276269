import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { setAlert } from '../../../actions/alert'
import { countryList, camelToSentence } from '../../../utils/misc';

const MailingDifferent = ({ parentMailingAddress, stateRef, setAlert }) => {
    const [hasError, setHasError] = useState(false);

    const [mailingAddress, setMailingAddress] = useState(parentMailingAddress);

    const updateFields = e => {
        setMailingAddress({
            ...mailingAddress,
            [e.target.name]: e.target.value
        });
        if (e.target.value.trim() && hasError === e.target.name) setHasError('');
    };

    const validate = () => {
        let fieldsToCheck = ['street', 'city', 'postalCode', 'country'];
        
        for (let i = 0, len = fieldsToCheck.length; i < len; i++) {
            if (!mailingAddress[fieldsToCheck[i]].trim()) {
                setAlert(camelToSentence(fieldsToCheck[i])+' is required', 'is-danger', 3000);
                setHasError(fieldsToCheck[i]);
                return false;
            }
        }

        return true;
    }

    stateRef.current = { mailingAddress, validate }

    useEffect(() => {
        stateRef.current = { ...stateRef.current, mailingAddress }

    }, [mailingAddress, stateRef])

    useEffect(() => {
        setMailingAddress(parentMailingAddress);
    }, [parentMailingAddress])

    return (
        <Fragment>
            <div className="columns field">
                <div className="column is-narrow">
                    <label htmlFor="unit" className="label">Unit</label>
                    <p className="control">
                        <input onChange={updateFields} value={mailingAddress.unit} className="input" name="unit" id="unit" type="text" placeholder="" />
                    </p>
                </div>
                <div className="column">
                    <label htmlFor="street" className="label">Street</label>
                    <p className="control">
                        <input onChange={updateFields} value={mailingAddress.street} className={classNames({ "input": true, "is-danger":hasError==='street'})} name="street" id="street" type="text" placeholder="" />
                    </p>
                </div>
            </div>


            <div className="columns field">
                <div className="column is-4">
                    <label htmlFor="city" className="label">City</label>
                    <p className="control">
                        <input onChange={updateFields} value={mailingAddress.city} className={classNames({ "input": true, "is-danger":hasError==='city'})} name="city" id="city" type="text" placeholder="" />
                    </p>
                </div>

                <div className="column is-4">
                    <label htmlFor="province" className="label">Province</label>
                    <p className="control">
                        <input onChange={updateFields} value={mailingAddress.province} className="input" name="province" id="province" type="text" placeholder="" />
                    </p>
                </div>

                <div className="column is-4">
                    <label htmlFor="postalCode" className="label">Postal Code</label>
                    <p className="control">
                        <input onChange={updateFields} value={mailingAddress.postalCode} className={classNames({ "input": true, "is-danger":hasError==='postalCode'})} name="postalCode" id="postalCode" type="text" placeholder="" />
                    </p>
                </div>
            </div>


            <div className="columns field">
                <div className="column is-full">
                    <label htmlFor="country" className="label">Country</label>
                    <div className="control has-icons-left">
                        <div className={classNames({ "select has-icons-left is-fullwidth": true, "is-danger":hasError==='country'})} >
                            <select onChange={updateFields} value={mailingAddress.country} required name="country" id="country">
                                <option value="">Country</option>
                                {countryList.map((val, index) => {
                                    return <option key={index} value={val}>{val}</option>
                                })}
                            </select>
                            <span className="icon is-left"><i className="fas fa-globe"></i></span>
                        </div>
                    </div>
                </div>                
            </div>
        </Fragment>
    )
}

MailingDifferent.propTypes = {
    parentMailingAddress: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, { setAlert })(MailingDifferent)