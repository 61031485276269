import React, {useState, useEffect} from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import classNames from 'classnames'
import * as types from './../actions/types'
import { store } from './../store'

const AccordionComponent = ({ tabs, history, type='', accordionIndex = -1, activeTab = -1, deleteAction = null }) => {
    const [activeIndex, setActive] = useState(activeTab>=0?activeTab:-1);

    const setActiveAccordion = (index, newAccordionIndex = -1) => {
        setActive(index);
        if (type==='joint' && index===-1) {
            store.dispatch({ type: types.SET_ACTIVE_JOINT,payload: newAccordionIndex});
        }
        
    }
    
    useEffect(() => {
        if (accordionIndex!==-1) {
            if (parseInt(/[^.]*$/.exec(history.location.hash)[0]) === accordionIndex ) store.dispatch({ type: types.SET_ACTIVE_JOINT, payload: accordionIndex});
            
            const unlisten = (type!=='joint')?null:history.listen((location, action) => {
                if (parseInt(/[^.]*$/.exec(location.hash)[0]) !== accordionIndex ) {
                    setActive(-1);
                }
                store.dispatch({ type: types.SET_ACTIVE_JOINT,payload: parseInt(/[^.]*$/.exec(location.hash)[0])});
            });   
        
            return () => {
                if (typeof(unlisten)==='function') unlisten();
            }
        }
      }, [history, type, accordionIndex])
    
    return <div className="accordionRow">
        <div className="accordionCol">
            <div className="accordionTabs">
                {
                    tabs.map((tab, index)=>{
                        return <div key={index} className="accordionTab ">                                    
                                    <a className={classNames({'accordionTab-label':true, 'has-icon': index===0 && typeof (deleteAction) === 'function', 'is-selected': activeIndex===index})} data-active={activeIndex===index} onClick={(event)=>{setActiveAccordion(event.target.dataset.active && index===activeIndex?-1:index)}} href={activeIndex===index?"#" + tab.anchor : "#!"}>
                                        {index===0 && typeof (deleteAction) === 'function' &&
                                            <span style={{position:'absolute', left:'1em'}} title="Delete" onClick={(e) => {e.preventDefault(); deleteAction(accordionIndex) }}><span className="is-link icon is-left"><i className="fas fa-trash"></i></span></span>
                                        }
                                        {tab.title} 
                                    </a>
                                    <div className="accordionTab-content">
                                        {tab.content}
                                    </div>
                                </div>
                    })
                }
            </div>
        </div>
    </div>

}


const mapStateToProps = state => ({
    
})

export default withRouter(connect(mapStateToProps, null)(AccordionComponent));