import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { setAlert } from '../../../actions/alert'
import { countryList } from '../../../utils/misc';

const TaxResidency = ({ parentCountryList, stateRef, setAlert }) => {
    const [hasError, setHasError] = useState(false);

    const [otherTaxResidentCountryList, setotherTaxResidentCountryList] = useState(parentCountryList);

    const validate = () => {
        for (let index in otherTaxResidentCountryList) {
            if (!otherTaxResidentCountryList[index].country.trim()) {
                setAlert('Country is required', 'is-danger', 3000);
                setHasError(parseInt(index));
                return false;
            }
            if (!otherTaxResidentCountryList[index].sin.trim()) {
                setAlert('Social Security Number is required', 'is-danger', 3000);
                setHasError(parseInt(index));
                return false;
            }
        }
        return true;
    }

    stateRef.current = { otherTaxResidentCountryList, validate }

    useEffect(() => {
        stateRef.current = { ...stateRef.current, otherTaxResidentCountryList }

    }, [otherTaxResidentCountryList, stateRef])

    useEffect(() => {
        setotherTaxResidentCountryList(parentCountryList);
    }, [parentCountryList])


    const deleteCountry = (index) => {
        setotherTaxResidentCountryList(
            otherTaxResidentCountryList.filter((_, i) => i !== index)
        )
    }

    const addCountry = () => {
        setotherTaxResidentCountryList(
            [...otherTaxResidentCountryList, { country: '', sin: '' }]
        )
    }

    const editCountry = (e) => {
        const key = parseInt(e.currentTarget.dataset.key);
        setotherTaxResidentCountryList(
            otherTaxResidentCountryList.map((entry, index) => index === key ? { country: e.target.value, sin: entry.sin } : entry)
        )
    }

    const editSIN = (e) => {
        const key = parseInt(e.currentTarget.dataset.key);
        setotherTaxResidentCountryList(
            otherTaxResidentCountryList.map((entry, index) => index === key ? { country: entry.country, sin: e.target.value } : entry)
        )
    }

    return (
        <Fragment>
            {otherTaxResidentCountryList.map(
                (entry, index) => {
                    return <Fragment key={index}>
                        <div className="columns">
                            <div className="column is-full-mobile is-half-tablet">
                                <div className="field">
                                    <label htmlFor={`taxCountry_${index}`} className="label">
                                        Country of tax residency
                                        {index > 0 && <span title="Delete country" onClick={() => { deleteCountry(index) }}><span className="is-link icon is-left"><i className="fas fa-trash"></i></span></span>}
                                    </label>
                                    <div className="control has-icons-left">
                                        <div className={classNames({ "select has-icons-left is-fullwidth": true, "is-danger": hasError === index && !entry.country })} >
                                            <select onChange={editCountry} data-key={index} value={entry.country} required name={`taxCountry_${index}`} id={`taxCountry_${index}`}>
                                                <option value="">Country</option>
                                                {countryList.filter(val=>val!=='Canada').map((val, index) => {
                                                    return <option key={index} value={val}>{val}</option>
                                                })}
                                            </select>
                                            <span className="icon is-left"><i className="fas fa-globe"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-full-mobile is-half-tablet">
                                <div className="field">
                                    <label style={{letterSpacing:'0'}} htmlFor={`taxSIN_${index}`} className="label">Social Security Number or Tax Indentification Number</label>
                                    <p className="control has-icons-left">
                                        <input onChange={editSIN} value={entry.sin} data-key={index} className={classNames({ "input": true, "is-danger": hasError === index && entry.country && !entry.sin.trim() })} name={`taxSIN_${index}`} id={`taxSIN_${index}`} required type="text" placeholder="" />
                                        <span className="icon is-left"><i className="fas fa-id-card"></i></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                }
            )}
            <button onClick={addCountry} className="button">Add country</button>
        </Fragment>
    )
}

TaxResidency.propTypes = {
    parentCountryList: PropTypes.array.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({

})

export default connect(mapStateToProps, { setAlert })(TaxResidency)
  