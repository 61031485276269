import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames';

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = {
  position: 'absolute',
  width: '100%',
  height: 42,
  transform: 'translate(0%, -50%)',
  borderRadius: 7,
  cursor: 'default',
  // border: '1px solid white',
}

const railInnerStyle = {
  position: 'absolute',
  width: '99%',
  height: 14,
  transform: 'translate(0%, -50%)',
  borderRadius: 7,
  pointerEvents: 'none',
  backgroundColor: 'rgb(155,155,155)',
}

export function SliderRail({ getRailProps }) {
  return (
    <Fragment>
      <div style={railOuterStyle} {...getRailProps()} />
      <div style={railInnerStyle} />
    </Fragment>
  )
}

SliderRail.propTypes = {
  getRailProps: PropTypes.func.isRequired,
}

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export function Handle({
  domain: [min, max],
  handle: { id, value, percent },
  start,
  disabled,
  beneficiary,
  spouse,
  deleteAction,
  editAction,
  lastChild,
  getHandleProps,
}) {

  return (
    <Fragment>
      {!lastChild &&
        <Fragment>
          <div
            style={{
              left: `${percent}%`,
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              WebkitTapHighlightColor: 'rgba(0,0,0,0)',
              zIndex: 5,
              cursor: 'ew-resize',
              width: 28,
              height: 42,
              backgroundColor: 'none',
            }}
            {...getHandleProps(id)}
          />

          <div
            className="sliderHandle"
            role="slider"
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value}
            style={{
              left: `${percent}%`,
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              zIndex: 2,
              width: 24,
              height: 24,
              borderRadius: '50%',
              boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
              backgroundColor: disabled || !beneficiary.color ? '#666' : beneficiary.color,
            }}
          />
        </Fragment>
      }
      <div
        className="sliderToggle"
        style={{
          left: `${start + (value - start) / 2}%`
        }}
      >
        <div className="sliderMarker" style={{ backgroundColor: disabled || !beneficiary.color ? '#c4c4c4' : beneficiary.color }}></div>
        <div className="sliderInput">{value - start}%</div>
      </div>

      <div
        className={classNames({ 'sliderCaption': true, 'narrow': (value - start < 5) })}
        style={{
          left: `${start + (value - start) / 2}%`
        }}
      >
        <div className="nameCaption">
          {beneficiary.relationship !== 'Estate' && beneficiary.type !== 'spouse' && <span title="Edit beneficiary" onClick={() => { editAction(parseInt(id.replace('$$-', ''))) }} className="is-link"><i style={{ color: '#c4c4c4' }} className="fas fa-edit"></i></span>}
          {beneficiary.type === 'spouse' && (spouse.firstName ? spouse.firstName : 'Spouse') + ((value - start >= 5) ? (spouse.lastName[0] ? ' ' + spouse.lastName[0] + '.' : '') : '')}
          {beneficiary.type !== 'spouse' && beneficiary.firstName + ((value - start >= 5) ? (beneficiary.lastName[0] ? ' ' + beneficiary.lastName[0] + '.' : '') : '')}
        </div>
        {beneficiary.relationship !== 'Estate' && <div><span title="Delete beneficiary" onClick={() => { deleteAction(parseInt(id.replace('$$-', ''))) }} className="is-link"><i style={{ color: '#c4c4c4' }} className="fas fa-trash"></i></span></div>}
      </div>
    </Fragment>
  )
}

Handle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

Handle.defaultProps = {
  disabled: false,
}

// *******************************************************
// KEYBOARD HANDLE COMPONENT
// Uses a button to allow keyboard events
// *******************************************************
export function KeyboardHandle({
  domain: [min, max],
  handle: { id, value, percent },
  disabled,
  getHandleProps,
}) {
  return (
    <button
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        left: `${percent}%`,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        zIndex: 2,
        width: 24,
        height: 24,
        borderRadius: '50%',
        boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
        backgroundColor: disabled ? '#666' : '#ffc400',
      }}
      {...getHandleProps(id)}
    />
  )
}

KeyboardHandle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

KeyboardHandle.defaultProps = {
  disabled: false,
}

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({ source, target, getTrackProps, disabled, beneficiary, spouse }) {

  return (
    <div
      style={{
        position: 'absolute',
        transform: 'translate(0%, -50%)',
        height: 14,
        zIndex: 1,
        backgroundColor: disabled || typeof (beneficiary) === 'undefined' || !beneficiary.color ? '#c4c4c4' : beneficiary.color,
        borderRadius: 7,
        cursor: 'default',
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  )
}

Track.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  target: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getTrackProps: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

Track.defaultProps = {
  disabled: false,
}

// *******************************************************
// TICK COMPONENT
// *******************************************************
export function Tick({ tick, count, format }) {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          marginTop: 14,
          width: 1,
          height: 5,
          backgroundColor: 'rgb(200,200,200)',
          left: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: 'absolute',
          marginTop: 22,
          fontSize: 10,
          textAlign: 'center',
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  )
}

Tick.propTypes = {
  tick: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  count: PropTypes.number.isRequired,
  format: PropTypes.func.isRequired,
}

Tick.defaultProps = {
  format: d => d,
}