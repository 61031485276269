import React, { Component } from 'react'
//import { Link } from 'react-router-dom'


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: '',
      ignoreScroll: false,
      lastScrollPos: 0
    };
  }
  componentDidMount() {
    this.container = document.getElementById("cnt");
    this.sticky = document.getElementById("steps").offsetTop;
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll(event) {
    if (!document.getElementById("stepContainer")) return;
    const stepsHeight = this.container.classList.contains('sticky') ? false : document.getElementById('steps').clientHeight;
    if (window.pageYOffset > this.sticky - 19) {
      if (stepsHeight) document.getElementById("stepContainer").style.paddingTop = `${stepsHeight + 60}px`;
      this.container.classList.add("sticky");
      this.container.classList.remove("is-multiline");
    } else {
      document.getElementById("stepContainer").style.paddingTop = `0px`;
      this.container.classList.remove("sticky");
      this.container.classList.add("is-multiline");
    }
  }

  render() {
    return (
      <div className="hero-head">
        <nav className="navbar" id="navv">
        </nav>
      </div>
    );
  }
}


export default Header