import * as types from "../actions/types";
const initialState = {
  title: "",
  firstName: "",
  middleName: "",
  lastName: "",
  dob: "",
  maritalStatus: "",
  dependents: 0,
  sin: "",
  countryCitizenship: "",
  otherTaxResident: "no",
  otherTaxResidentCountryList: [{ country: "", sin: "" }],
  borrowedList: [{ amount: "", type: "Pre-existing", date: "", institution: "", selectedAccounts: [] }],
  insiderList: [{ company: "", market: "", symbol: "", insider: false, controlperson: false }],
  thirdPartyList: [{ name: "", occupation: "", relationship: "", dob: "", address: "" }],
  experienceYears: 0,
  email: "",
  phone: "",
  unit: "",
  street: "",
  city: "",
  province: "",
  postalCode: "",
  country: "",
  mailingDifferent: "",
  mailingAddress: { unit: "", street: "", city: "", province: "", postalCode: "", country: "" },
  annualIncome: "",
  status: "Employed",
  employersName: "",
  occupation: "",
  typeOfBusiness: "",
  spouse: { title: "", firstName: "", middleName: "", lastName: "", sin: "", dob: "", email: "", phone: "", annualIncome: "", status: "Employed", employersName: "", occupation: "", typeOfBusiness: "" },
  investmentExperienceState: "cash",
  investmentExperience: {
    cash: [
      { title: "Money market funds", checked: false },
      { title: "Savings bonds", checked: false },
      { title: "Guaranteed Investment Certificates (GICs)", checked: false },
      { title: "T-Bills", checked: false },
    ],
    fixedIncome: [
      { title: "Bonds", checked: false },
      { title: "Convertible debentures", checked: false },
      { title: "Non-convertible debentures", checked: false },
      { title: "Non-convertible preferred shares", checked: false },
      { title: "Strip bonds", checked: false },
    ],
    equity: [
      { title: "Common shares", checked: false },
      { title: "Convertible preferred shares", checked: false },
      { title: "Exchange Traded Funds (ETFs)", checked: false },
      { title: "Flow through shares", checked: false },
      { title: "Mutual funds", checked: false },
      { title: "New issues", checked: false },
      { title: "Trust units", checked: false },
      { title: "Warrants", checked: false },
    ],
    alternative: [
      { title: "Structured products/Hedge funds", checked: false },
      { title: "Private placements", checked: false },
      { title: "Foreign exchanges", checked: false },
      { title: "Options", checked: false },
      { title: "Leveraged ETFs/Inverse ETFs", checked: false },

      { title: "Other", checked: false },
    ],
  },
  affiliations: {
    restrictions: { question: "Do you have any restrictions regarding the management of your account?", detailsText: { label: "Please describe any restrictions you have regarding the management of your account.", placeholder: "Details", answer: "" }, answer: "no" },
    borrowedMoney: { question: "Have you borrowed money to invest?", answer: "no", hasPopupOnYes: true },
    controlPerson: { question: "Are you or your spouse considered an Insider<sup>1</sup> and/or a Control Person<sup>2</sup> (CP) of any public companies listed in Canada or the U.C?", answer: "no", hasPopupOnYes: true, info: '<strong>1 - An "Insider" is:</strong> an officer, director, or promoter of a publicly traded Canadian or U.S. company and/or a person with direct or indirect beneficial ownership of, control or direction over (or combination thereof) 10% or more of the voting rights attached to the securities of a publicly traded company listed in Canada (5% or more for a publicly listed company in the U.S.).<br/> <strong>2 - A "Control Person":</strong> holds or exercises control or direction over, or has any agreement, arrangement, commitment, or understanding (whether or not in writing) individually or with any other persons with respect to 20% or more of the voting rights attached to the securities of a publicly traded company listed in Canada (10% or more for a publicly listed company in the U.S.).' },
    otherFirms: { question: "Do you or your spouse have any other accounts at other brokerage firms or control the traiding in any other accounts?", answer: "no" },
    politicallyExposed: {
      question: "Are you a Politically Exposed Person or a family member of a Politically Exposed Person?",
      hasNote: true,
      answer: "no",
      info: "What is a Politically Exposed Person?  You are a politically exposed person if you hold or you have ever held one of the following offices or positions: <ul> <li>a head of state or government;</li> <li>a member of the executive council of government or member of a legislature;</li> <li>a deputy minister (or equivalent);</li> <li>an ambassador or an ambassador's attaché or counsellor;</li> <li>a military general (or higher rank);</li> <li>a president of a state-owned company or bank;</li> <li>a head of a government agency;</li> <li>a judge; or</li> <li>a leader or president of a political party in a legislature.</li> </ul> You are also considered a politically exposed person if you are a family member of an individual described above. In this context, a family member means one of the following: <ul> <li>mother or father;</li> <li>child;</li> <li>spouse or common-law partner;</li> <li>spouse's or common-law partner's mother or father and</li> <li>brother, sister, half-brother or half-sister (that is, any other child of the individual's mother or father).</li> </ul>",
      detailsCheckboxes: [
        { label: "Foreign Politically Exposed Person", answer: false },
        { label: "Domestic Politically Exposed Person", answer: false },
        { label: "Head of International Organization", answer: false },
      ],
      detailsText: { label: "Please provide details regarding your Politically Exposed Person status", placeholder: "Details", answer: "" },
    },

    otherAccountWithUs: { question: "Do you have any other accounts with us?", answer: "no" },
    iirocEmployee: { question: "Are you or anyone you live with an employee, officer, director or partner of an IIROC member firm?", answer: "no", hasNote: true },
    iaEmployee: { question: "Are you or anyone you live with an employee, officer, director or partner of the AO Inc.?", answer: "no" },
    thirdParty: {
      question: "Will any party, other than a Portfolio Manager, be associated with your account?",
      answer: "no",
      detailsCheckboxes: [
        { label: "Third Party", answer: false, hasNote: true },
        { label: "General Power of Attorney", answer: false, hasNote: true },
        { label: "Guarantor", answer: false, hasNote: true },
        { label: "Controlling Person (of a legal entity)", answer: false, hasNote: true },
        { label: "Financial Interest: Will any other party, not named above, be providing instructions for, or exerting control over this account?", answer: false, hasNote: true },
      ],
    },
  },
  disclosure: 0,
  language: "en",
  securityholder: 0,
  duplicate: 0,
  confirmations: { duplicateConfirmations: false, duplicateStatements: false, relationship: "", title: "", firstName: "", middleName: "", lastName: "", unit: "", street: "", city: "", province: "", postalCode: "", country: "" },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_FIELD:
      if (action.target.name.includes(".")) {
        const tNames = action.target.name.split(".");
        return {
          ...state,
          [tNames[0]]: {
            ...state[tNames[0]],
            [tNames[1]]: tNames[0] === "affiliations" ? (tNames[2] ? { ...state[tNames[0]][tNames[1]], [tNames[2]]: { ...state[tNames[0]][tNames[1]][tNames[2]], answer: action.target.value } } : { ...state[tNames[0]][tNames[1]], answer: action.target.value }) : action.target.value,
          },
        };
      } else
        return {
          ...state,
          [action.target.name]: action.target.value,
        };
    case types.UPDATE_AFFILIATIONS_CHECKBOXES:
      const tNames = action.target.name.split(".");
      return {
        ...state,
        affiliations: {
          ...state.affiliations,
          [tNames[1]]: {
            ...state.affiliations[tNames[1]],
            detailsCheckboxes: state.affiliations[tNames[1]].detailsCheckboxes.map((checkbox, index) => {
              return index === parseInt(tNames[3]) ? { ...checkbox, answer: action.target.checked } : checkbox;
            }),
          },
        },
      };
    case types.UPDATE_EXPERIENCE:
      return {
        ...state,
        investmentExperience: {
          ...state.investmentExperience,
          [state.investmentExperienceState]: state.investmentExperience[state.investmentExperienceState].map((investment, index) => {
            return index === parseInt(action.target.dataset.key) ? { ...investment, checked: action.target.checked } : investment;
          }),
        },
      };
    case types.UPDATE_EXPERIENCE_STATE:
      return {
        ...state,
        investmentExperienceState: action.key,
      };

    case types.UPDATE_TAX_RESIDENCY:
      return {
        ...state,
        otherTaxResident: "yes",
        otherTaxResidentCountryList: action.otherTaxResidentCountryList,
      };
    case types.UPDATE_BORROWED:
      return {
        ...state,
        affiliations: { ...state.affiliations, borrowedMoney: { ...state.affiliations.borrowedMoney, answer: "yes" } },
        borrowedList: action.borrowedList,
      };
    case types.UPDATE_MAILING:
      return {
        ...state,
        mailingDifferent: "yes",
        mailingAddress: action.mailingAddress,
      };
    case types.UPDATE_INSIDER:
      return {
        ...state,
        affiliations: { ...state.affiliations, controlPerson: { ...state.affiliations.controlPerson, answer: "yes" } },
        insiderList: action.insiderList,
      };
    case types.UPDATE_TRHIRDPARTY:
      return {
        ...state,
        thirdPartyList: action.thirdPartyList,
      };

    case types.UPDATE_CONFIRMATIONS:
      return {
        ...state,
        confirmations: action.confirmations,
      };
    default:
      return state;
  }
}
