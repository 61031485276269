import { combineReducers } from 'redux';
import alert from './alert';
import modal from './modal';
import accData from './accData';
import about from './about';
import aboutJoint from './joint';
import beneficiaries from './beneficiaries';
import banking from './banking';
import * as types from './../actions/types';
import storage from 'redux-persist/lib/storage';

// export default combineReducers({
//     alert, modal, accData, about, aboutJoint, beneficiaries, banking
// })

const appReducer = combineReducers({
    alert, modal, accData, about, aboutJoint, beneficiaries, banking
})

const rootReducer = (state, action) => {
    if (action.type === types.CLEAR_STATE) {
        storage.removeItem('persist:root')
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer