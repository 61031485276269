import React, {Fragment, useState, useEffect} from 'react'
import Spinner from '../components/layout/spinner'
import WebAPI from '../WebAPI';
import { connect } from 'react-redux'
import { setAlert } from '../actions/alert'

const Invisor = ({setAlert}) => {
    const [response, setResponse] = useState(false);
    useEffect(()=>{
        async function loadData() {
            try {
                const res = await WebAPI.post('GetADAdvisorClientList', {ClientID:2614, EntityID: 0, TenantID:2});
                setResponse(JSON.parse(res.data.d).Data);
            } catch (error) {
                setAlert('API Error', 'is-danger', 3000);
                setResponse(true);
            }           
        }
        loadData();
    }, [setAlert]);

    return !response?<Spinner/>:
    <Fragment>    
        {
           (response.RegistrantFirms)?response.RegistrantFirms[0].Branch[0].AdvisoryFirms[0].Advisors[0].Clients.map((x,index)=>{
                return <div key={index} className="card">
                    <header className="card-header">
                        <p className="card-header-title">
                        {x.ClientFirstName} {x.ClientLastName} (#{x.ClientID})
                        </p>
                    </header>
                    <div className="card-content">
                        <div className="content">
                            {x.ClientEmail}<br/>{x.HomePhone}                            
                        </div>
                    </div>
                    <footer className="card-footer">
                        {x.Status}
                    </footer>
                </div>
            }):''
        }
    </Fragment>;
}

export default connect(null, { setAlert })(Invisor)