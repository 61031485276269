import React, { useRef, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import * as types from './../../../actions/types'
import { store } from './../../../store'
import TabsComponent from './../../../utils/tabsComponent'
import Employment from './Employment'
import AccordionComponent from './../../../utils/accordionComponent'
import InputMask from 'react-input-mask'
import { connect } from 'react-redux'
import TaxResidency from './TaxResidency'
import AffiliationsQuestion from './AffiliationsQuestion'
import Insider from './Insider'
import MailingDifferent from './MailingDifferent.js'
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { countryList } from '../../../utils/misc'
import MultiSelect from '../../../utils/multiSelect'
import { getAccList } from '../../../utils/misc'

const StepJoint = (props) => {
    const { history, updateFields, updateAffiliationsCheckboxes } = props;
    if ( !props.accData.accountTypes.filter(account => { return account.selected && account.accsToAdd.filter(acctoAdd => { return acctoAdd.isJoint }).length }).length) history.push('/');

    const taxResidencyRef = useRef({});
    const mailingDifferentRef = useRef({});
    const insiderRef = useRef({});
    const allAccList = getAccList(props.accData.accountTypes, 'joint');
    let allJointsUnSelectedAccounts = [...allAccList];

    const accList = [];

    props.aboutJoint.forEach((joint, jIndex)=>{
        if (joint.selectedAccounts===null && jIndex===0)  {
            joint.selectedAccounts = allAccList.map(acc=>acc.value);
            updateFields({target:{name:'selectedAccounts',value: joint.selectedAccounts}});
        }
        
        
        const accsToDisplay = allJointsUnSelectedAccounts.filter( x => props.aboutJoint.find((y, yindex)=>yindex!==jIndex && y.selectedAccounts.includes(x.value)) ? false : true );

        accList.push(accsToDisplay);
        allJointsUnSelectedAccounts = allJointsUnSelectedAccounts.filter(x => !joint.selectedAccounts.includes(x.value) );
        //if (joint.selectedAccounts.length===0) allJointsUnSelectedAccounts = [];
    }); 
    

    const jointsToDisplay = [...props.aboutJoint]; //props.aboutJoint.filter((joint, jIndex)=>(accList[jIndex].length>0));
    
    let totalSelected = 0; let emptyJoints = 0;
    props.aboutJoint.forEach(joint=>{
        if ( Array.isArray(joint.selectedAccounts)) {
            if (joint.selectedAccounts.length>0) totalSelected+=joint.selectedAccounts.length; else emptyJoints++;
        } else emptyJoints++;
    })

    return <div className="stepContainer" id="stepContainer">{jointsToDisplay.map((joint, jIndex)=>{

        const {selectedAccounts, title, firstName, middleName, lastName, dob, maritalStatus, dependents, sin, countryCitizenship, otherTaxResident, otherTaxResidentCountryList, insiderList, spouse, email, phone, unit, street, city, province, postalCode, country, mailingDifferent, mailingAddress, annualIncome, status, employersName, occupation, typeOfBusiness,  affiliations } = joint;
        const joint_title = (jointsToDisplay.length<2)?'Joint applicant': ( (!firstName && !lastName)? `Joint applicant #${jIndex+1}`: firstName+' '+lastName);

        const showResidency = () => store.dispatch({ type: types.SET_MODAL, payload: { title: 'Tax residency info', saveButton: saveOtherTaxResidentCountryList, content: <TaxResidency stateRef={taxResidencyRef} parentCountryList={otherTaxResidentCountryList} /> } })
        const showMailing = () => store.dispatch({ type: types.SET_MODAL, payload: { title: 'Mailing address', saveButton: saveMailing, content: <MailingDifferent stateRef={mailingDifferentRef} parentMailingAddress={mailingAddress} /> } })    
        const showInsider = () => store.dispatch({ type: types.SET_MODAL, payload: { title: ' Insider and/or Control Person details', saveButton: saveInsider, content: <Insider stateRef={insiderRef} parentInsiderList={insiderList} /> } })
        const saveOtherTaxResidentCountryList = () => { return taxResidencyRef.current.validate() ? props.updateTaxResidency(taxResidencyRef) : false; }
        const saveInsider = () => { return insiderRef.current.validate() ? props.updateInsider(insiderRef) : false; }
        const saveMailing = () => { return mailingDifferentRef.current.validate() ? props.updateMailing(mailingDifferentRef) : false; }


        const updateAccountsList = (selction) => {
            updateFields({target:{name:'selectedAccounts',value: selction}});
        }



        const tabsClient = [{
            name: "Personal Information",
            anchor: `personal.${jIndex}`,
            content: <Fragment>
                <div className="columns is-centered is-mobile is-multiline is-fullwidth topcolumns">
                    <div className="column is-half-mobile is-one-third-tablet is-one-third-desktop">
                        {(accList[jIndex].length>1 || jointsToDisplay.length>1) &&
                        <Fragment>
                        <div className="field">
                            <label className="label">Joint applicant's accounts</label>
                            <div className={classNames({'field':true, 'hideSelectAll': (jIndex===0 && jointsToDisplay.length>1)})}>
                                <MultiSelect invalid={true} name={`accTypes`} id={`accTypes`} options={accList[jIndex]} disableSearch={true}
                                selected={selectedAccounts.filter(x=> props.accData.accountTypes.filter(acc => acc.selected && acc.id===parseInt(x.split('_')[0].toLowerCase()) ).length)} overrideStrings={{selectSomeItems: "Select account(s)", allItemsAreSelected: (jIndex>0 || jointsToDisplay.length>1)?'off':"All Joint Accounts", selectAll: "Select All" }} onSelectedChanged={selction =>updateAccountsList(selction)  }/>
                            </div>
                        </div>
                        </Fragment>
                        }
                       
                        <div className="field doubleLayer">
                            <div className="columns">
                                <div className="column is-narrow"><div className="select is-fullwidth"><select onChange={updateFields} value={title} required name="title"><option value="">Title</option><option value="Mr.">Mr.</option> <option value="Mrs.">Mrs.</option> <option value="Ms.">Ms.</option> <option value="Dr.">Dr.</option></select></div></div>
                                <div className="column"><input onChange={updateFields} value={firstName} className="input" name="firstName" type="text" required placeholder="First Name" /></div>
                            </div>
                        </div>
                        {(accList[jIndex].length>1 || jIndex>0)?
                        <div className="field doubleLayer">
                            <div className="columns">
                                <div className="column">    <input onChange={updateFields} value={middleName} className="input" name="middleName" type="text" placeholder="Middle Name" />  </div>
                                <div className="column">    <input onChange={updateFields} value={lastName} className="input" name="lastName" type="text" required placeholder="Last Name" />  </div>
                            </div>                             
                        </div> :
                        <Fragment>
                        <div className="field">
                            <input onChange={updateFields} value={middleName} className="input" name="middleName" type="text" placeholder="Middle Name" />
                        </div>
                        <div className="field">
                            <input onChange={updateFields} value={lastName} className="input" name="lastName" type="text" required placeholder="Last Name" />
                        </div>
                        </Fragment>

                        }
                       
                    </div>
                    <div className="column is-half-mobile is-one-third-tablet is-one-third-desktop">
                        <div className="field">
                            <label htmlFor="dob" className="label">Date of birth</label>
                            <p className="control has-icons-left">
                                <input onChange={updateFields} value={dob} className="input" name="dob" id="dob" required type="date" placeholder="mm/dd/yyyy" />
                                <span className="icon is-left"><i className="fas fa-calendar-alt"></i></span>
                            </p>
                        </div>

                        <div className="field">
                            <label htmlFor="maritalStatus" className="label">Marital Status</label>
                            <div className="control has-icons-left">
                                <div className="select has-icons-left is-fullwidth"><select onChange={updateFields} value={maritalStatus} required name="maritalStatus" id="maritalStatus"><option value="">Marital Status</option><option value="Single">Single</option> <option value="Married">Married</option> <option value="Common-law">Common-law</option> <option value="Divorced">Divorced</option>  <option value="Widowed">Widowed</option> </select> <span className="icon is-left"><i className="fas fa-ring"></i></span> </div>
                            </div>
                        </div>

                        <div className="field label-right"> <input onChange={updateFields} value={dependents} className="input" id="dependents" name="dependents" min="0" type="number" /> <label className="label" htmlFor="dependents">Number of dependents</label></div>
                    </div>
                    <div className="column is-full-mobile is-one-third-tablet is-one-third-desktop">
                        <div className="field">
                            <label htmlFor="sin" className="label">Social Insurance Number</label>
                            <p className="control has-icons-left has-icons-right">
                                <InputMask onChange={updateFields} value={sin} className="input" name="sin" id="sin" type="text" required placeholder="111-111-111" mask="999-999-999" maskChar="" />
                                <span onClick={() => store.dispatch({ type: types.SET_MODAL, payload: { title: 'SIN info', saveButton: false, content: '<strong>Why do I have to provide SIN?</strong> Because you may earn interest or other income in your account, we are required to use your SIN to report this income to the government. For more information on who can and cannot ask you to provide your SIN, please review the following link: <a href="http://www.servicecanada.gc.ca/eng/sin/protect/provide.shtml?INV=807f1f56-1775" target="_blank">www.servicecanada.gc.ca/eng/sin/protect/provide.shtml</a>' } })} className="icon is-right has-actions"><i style={{ color: '#719CF0' }} className="fas fa-info-circle"></i></span>
                                <span className="icon is-left"><i className="fas fa-id-card"></i></span>
                            </p>
                        </div>
                        <div className="field">
                            <label htmlFor="countryCitizenship" className="label">Country of citizenship</label>
                            <div className="control has-icons-left">
                                <div className="select has-icons-left is-fullwidth">
                                    <select onChange={updateFields} value={countryCitizenship} required name="countryCitizenship" id="countryCitizenship">
                                        <option value="">Country</option>
                                        {countryList.map((val, index) => {
                                            return <option key={index} value={val}>{val}</option>
                                        })}
                                    </select>
                                    <span className="icon is-left"><i className="fas fa-globe"></i></span>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <div className="columns reversed">
                                <div className="column is-narrow">
                                    <div className={classNames({ "control has-actions": true, "has-icons-left": otherTaxResident === 'yes' })}>
                                        <div className="select is-fullwidth">
                                            <select onChange={(e) => { if (e.target.value === 'yes') showResidency(); updateFields(e); }} value={otherTaxResident} name="otherTaxResident" id="otherTaxResident"><option value={'no'}>No</option><option value={'yes'}>Yes</option></select>
                                        </div>
                                        {otherTaxResident === 'yes' && <span onClick={() => showResidency()} className="icon is-left has-actions"><i style={{ color: '#3273dc' }} title="Edit" className="fas fa-edit"></i></span>}
                                    </div>
                                </div>
                                <div className="column labelonly">
                                    <label className="label" htmlFor="otherTaxResident"><span>Is {joint_title} a tax resident of a country other than Canada?</span>{otherTaxResident === 'yes' && <span onClick={() => showResidency()} className="is-link">Edit</span>} </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment >,
            icon: 'fas fa-user'
        },
        {
            name: "Contact Details",
            anchor: `contact.${jIndex}`,
            content: <Fragment>
                <div style={{ paddingTop: '1rem' }} className="columns is-mobile is-multiline is-fullwidth topcolumns">
                    <div className="column is-half-mobile is-half-tablet is-one-third-desktop">
                        <div className="field">
                            <label htmlFor="email" className="label">Email address</label>
                            <p className="control has-icons-left">
                                <input onChange={updateFields} value={email} className="input" name="email" id="email" required type="text" placeholder="email@domain.com" />
                                <span className="icon is-left"><i className="fas fa-at"></i></span>
                            </p>
                        </div>

                        <div className="field">
                            <label htmlFor="phone" className="label">Phone number</label>
                            <p className="control has-icons-left">
                                <InputMask onChange={updateFields} value={phone} className="input" name="phone" id="phone" required type="text" placeholder="1 (111) 111-1111" mask="9 (999) 999-99-99" maskChar="" />
                                <span className="icon is-left"><i className="fas fa-phone"></i></span>
                            </p>
                        </div>
                    </div>
                    <div className="column is-half-mobile is-half-tablet is-two-thirds-desktop">
                        <h4 style={{ marginBottom: '1.5rem', marginTop: '-3.9rem', textAlign: 'center', color: '#363636', paddingTop: '0px' }}>Residential or legal address</h4>
                        <div className="columns field">
                            <div className="column is-narrow">
                                <label htmlFor="unit" className="label">Unit</label>
                                <p className="control">
                                    <input onChange={updateFields} value={unit} className="input" name="unit" id="unit" type="text" placeholder="" />
                                </p>
                            </div>
                            <div className="column">
                                <label htmlFor="street" className="label">Street</label>
                                <p className="control">
                                    <input onChange={updateFields} value={street} className="input" name="street" id="street" type="text" placeholder="" />
                                </p>
                            </div>
                        </div>


                        <div className="columns field">
                            <div className="column is-4">
                                <label htmlFor="city" className="label">City</label>
                                <p className="control">
                                    <input onChange={updateFields} value={city} className="input" name="city" id="city" type="text" placeholder="" />
                                </p>
                            </div>

                            <div className="column is-4">
                                <label htmlFor="province" className="label">Province</label>
                                <p className="control">
                                    <input onChange={updateFields} value={province} className="input" name="province" id="province" type="text" placeholder="" />
                                </p>
                            </div>

                            <div className="column is-4">
                                <label htmlFor="postalCode" className="label">Postal Code</label>
                                <p className="control">
                                    <input onChange={updateFields} value={postalCode} className="input" name="postalCode" id="postalCode" type="text" placeholder="" />
                                </p>
                            </div>
                        </div>


                        <div className="columns field">
                            <div className="column is-half-tablet">
                                <label htmlFor="country" className="label">Country</label>
                                <div className="control has-icons-left">
                                    <div className="select has-icons-left is-fullwidth">
                                        <select onChange={updateFields} value={country} required name="country" id="country">
                                            <option value="">Country</option>
                                            {countryList.map((val, index) => {
                                                return <option key={index} value={val}>{val}</option>
                                            })}
                                        </select>
                                        <span className="icon is-left"><i className="fas fa-globe"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="column is-half-tablet">
                                <div className="field">
                                    <div className="columns reversed">
                                        <div className="column is-narrow">
                                            <div className={classNames({ "control has-actions": true, "has-icons-left": mailingDifferent === 'yes' })}>
                                                <div className="select is-fullwidth">
                                                    <select onChange={(e) => { if (e.target.value === 'yes') showMailing(); updateFields(e); }} value={mailingDifferent} name="mailingDifferent" id="mailingDifferent"><option value={'no'}>No</option><option value={'yes'}>Yes</option></select>
                                                </div>
                                                {mailingDifferent === 'yes' && <span onClick={() => showMailing()} className="icon is-left has-actions"><i style={{ color: '#3273dc' }} title="Edit" className="fas fa-edit"></i></span>}
                                            </div>

                                        </div>
                                        <div className="column labelonly">
                                            <label className="label" htmlFor="mailingDifferent"><span>Is mailing address different from above?</span>{mailingDifferent === 'yes' && <span onClick={() => showMailing()} className="is-link">Edit</span>} </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>,
            icon: 'fas fa-envelope-open'
        },
        {
            name: <Fragment>Employment <span className='is-hidden-tablet-only is-hidden-mobile'>Information</span></Fragment>,
            anchor: `employment.${jIndex}`,
            content: <Employment prefix="" title={joint_title} annualIncome={annualIncome} status={status} employersName={employersName} occupation={occupation} typeOfBusiness={typeOfBusiness} updateFields={updateFields} />,
            icon: 'fas fa-briefcase'
        }
        ]

        const tabsSpouse = [{
            name: "Personal Information",
            anchor: `personalSpouse.${jIndex}`,
            content: <Fragment>
                <div className="columns is-centered is-mobile is-multiline is-fullwidth topcolumns">
                <div className="column is-half-mobile">
                    <div className="field">
                        <div className="columns">
                            <div className="column is-narrow"><div className="select is-fullwidth"><select onChange={updateFields} value={spouse.title} required name="spouse.title"><option value="">Title</option><option value="Mr.">Mr.</option> <option value="Mrs.">Mrs.</option> <option value="Ms.">Ms.</option> <option value="Dr.">Dr.</option></select></div></div>
                            <div className="column"><input onChange={updateFields} value={spouse.firstName} className="input" name="spouse.firstName" required type="text" placeholder="First Name" /></div>
                        </div>
                    </div>
                    <div className="field"> <input onChange={updateFields} value={spouse.middleName} className="input" name="spouse.middleName" type="text" placeholder="Middle Name" /> </div>
                    <div className="field"> <input onChange={updateFields} value={spouse.lastName} className="input" name="spouse.lastName" required type="text" placeholder="Last Name" /> </div>
                </div>
                <div className="column is-half-mobile">
                        <div className="field doubleLayer">
                            <div className="columns">
                                <div className="column">
                                    <div className="field">
                                        <label htmlFor="spouse.dob" className="label">Date of birth</label>
                                        <p className="control has-icons-left">
                                            <input onChange={updateFields} value={spouse.dob} className="input" name="spouse.dob" id="spouse.dob" required type="date" placeholder="mm/dd/yyyy" />
                                            <span className="icon is-left"><i className="fas fa-calendar-alt"></i></span>
                                        </p>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label htmlFor="spouse.sin" className="label">Social Insurance Number</label>
                                        <p className="control has-icons-left has-icons-right">
                                            <InputMask onChange={updateFields} value={spouse.sin} className="input" name="spouse.sin" id="spouse.sin" type="text" required placeholder="111-111-111" mask="999-999-999" maskChar="" />
                                            <span onClick={() => store.dispatch({ type: types.SET_MODAL, payload: { title: 'SIN info', saveButton: false, content: '<strong>Why do I have to provide SIN?</strong> Because you may earn interest or other income in your account, we are required to use your SIN to report this income to the government. For more information on who can and cannot ask you to provide your SIN, please review the following link: <a href="http://www.servicecanada.gc.ca/eng/sin/protect/provide.shtml?INV=807f1f56-1775" target="_blank">www.servicecanada.gc.ca/eng/sin/protect/provide.shtml</a>' } })} className="icon is-right has-actions"><i style={{ color: '#719CF0' }} className="fas fa-info-circle"></i></span>
                                            <span className="icon is-left"><i className="fas fa-id-card"></i></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    <div className="field">
                        <label htmlFor="spouse.email" className="label">Email address</label>
                        <p className="control has-icons-left">
                            <input onChange={updateFields} value={spouse.email} className="input" name="spouse.email" id="spouse.email" required type="text" placeholder="email@domain.com" />
                            <span className="icon is-left"><i className="fas fa-at"></i></span>
                        </p>
                    </div>

                    <div className="field">
                        <label htmlFor="spouse.phone" className="label">Phone number</label>
                        <p className="control has-icons-left">
                            <InputMask onChange={updateFields} value={spouse.phone} className="input" name="spouse.phone" id="spouse.phone" required type="text" placeholder="1 (111) 111-1111" mask="9 (999) 999-99-99" maskChar="" />
                            <span className="icon is-left"><i className="fas fa-phone"></i></span>
                        </p>
                    </div>
                </div>
            </div>
            </Fragment >,
            icon: 'fas fa-user'
        },
        {
            name: <Fragment>Employment Information</Fragment>,
            anchor: `employmentSpouse.${jIndex}`,
            content: <Employment prefix="spouse." annualIncome={spouse.annualIncome} status={spouse.status} employersName={spouse.employersName} occupation={spouse.occupation} typeOfBusiness={spouse.typeOfBusiness} updateFields={updateFields} />,
            icon: 'fas fa-briefcase'
        }
        ]

        const affiliationsClient = <div className="accordionContentWrapper">
            <div className="columns field is-centered is-mobile is-multiline is-fullwidth topcolumns affiliationsTab">
                {
                    [0, 1].map((column) => {
                        return <div key={column} className="column is-full-mobile is-half-tablet">
                            {Object.keys(affiliations).slice(column * Math.ceil(Object.keys(affiliations).length / 2), Math.ceil(Object.keys(affiliations).length * ((column + 1) / 2))).map((key) => {
                                return <AffiliationsQuestion key={key} index={key} updateFields={updateFields} updateAffiliationsCheckboxes={(e)=>{updateAffiliationsCheckboxes(e);}} popupAction={{'controlPerson': showInsider }[key]} question={affiliations[key]} />
                            })}
                        </div>
                    })
                }
            </div>
            <div style={{ marginBottom: '-0.7rem' }} className="clear"></div>
        </div>

        const accordiontabs = [{ title: `${joint_title} information`, anchor: `personal.${jIndex}`, content: <TabsComponent activeTab="1" tabs={tabsClient} /> }]
        if (['Married', 'Common-law'].includes(maritalStatus)) accordiontabs.push({ title: joint_title+' '+(maritalStatus === 'Married' ? 'Spouse' : 'Partner') + ' information', anchor: `personalSpouse.${jIndex}`, content: <TabsComponent tabs={tabsSpouse} /> });
        accordiontabs.push({ title: `${joint_title} affiliations`, anchor: `affiliationsClient.${jIndex}`, content: affiliationsClient });

        let accordionIndex = -1; 
        if (history.location.hash === `#personal.${jIndex}`) accordionIndex=0; if ((['Married', 'Common-law'].includes(maritalStatus)) && tabsSpouse.find(tab => '#' + tab.anchor === history.location.hash)) accordionIndex = 1; else if (history.location.hash === `#affiliationsClient.${jIndex}`) accordionIndex = (['Married', 'Common-law'].includes(maritalStatus)) ? 2 : 1; 

        return <div className={classNames({'secondaryJoint': jIndex>0})} key={jIndex} ><AccordionComponent deleteAction={jIndex>0?props.deleteJoint:null} activeTab={accordionIndex}tabs={accordiontabs} type="joint" accordionIndex={jIndex} /></div>
    })}
        {
            allAccList.length>1 && ((allAccList.length-emptyJoints) > (totalSelected) ) && <Fragment>
                <button style={{marginTop:'10px'}}onClick={() => {
                    props.addJoint();
                }} className="button is-pulled-right">Add New Joint Applicant</button>
                <div className="clear"></div>
            </Fragment>
        }    
    </div >;
}


StepJoint.propTypes = {
    accData: PropTypes.object.isRequired,
    aboutJoint: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    accData: state.accData,
    aboutJoint: state.aboutJoint.jointsArray
})

const mapDispatchToProps = dispatch => ({
    updateFields: e => {
        return dispatch({
            type: types.UPDATE_FIELD_JOINT,
            target: e.target
        })
    },
    updateAffiliationsCheckboxes: e => dispatch({
        type: types.UPDATE_AFFILIATIONS_CHECKBOXES_JOINT,
        target: e.target
    }),
    updateTaxResidency: taxResidencyRef => dispatch({
        type: types.UPDATE_TAX_RESIDENCY_JOINT,
        otherTaxResidentCountryList: taxResidencyRef.current.otherTaxResidentCountryList
    }),
    updateInsider: insiderRef => dispatch({
        type: types.UPDATE_INSIDER_JOINT,
        insiderList: insiderRef.current.insiderList
    }),
    updateMailing: mailingDifferentRef => dispatch({
        type: types.UPDATE_MAILING_JOINT,
        mailingAddress: mailingDifferentRef.current.mailingAddress
    }),
    addJoint: () => dispatch({
        type: types.ADD_JOINT
    }),
    deleteJoint: index=>dispatch({
        type: types.DELETE_JOINT,
        payload: index
    })

});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepJoint))